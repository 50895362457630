@import "../../index.scss";

.autotranslate {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 3px 7px;
  height: 24px;
  border-radius: 34px;
  background: $blue_gradient;
  cursor: pointer;
  width: 155px;
  box-sizing: border-box;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover,
  &:focus,
  &:active {
    span {
      color: $blue_text;
    }

    i {
      background: url("../../assets/controls/autotranslate-hover.svg") no-repeat;
    }
  }

  span {
    display: contents;
    margin-right: 5px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $white;
    font-family: Inter;
  }

  i {
    display: block;
    width: 20px;
    height: 17px;
    margin-left: 5px;
    background: url("../../assets/controls/autotranslate.svg") no-repeat;
    background-size: cover;
  }
}
