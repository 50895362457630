@import "../../index.scss";

.file-modal {
  max-width: 370px;
  width: 100%;

  &__form {
    &-row {
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0;

    button {
      margin: 0px;
    }

    button:not(:last-child) {
      margin-right: 50px;
    }
  }

  input[name="label"] {
    margin-top: 5px;
  }
}
