@import "../../index.scss";

.notification-filter {
  padding: 10px;
  background: #212745;
  box-shadow: 1px 1px 2px rgba(50, 59, 104, 0.3),
    -1px -1px 2px rgba(17, 20, 35, 0.5),
    inset -1px 1px 2px rgba(17, 20, 35, 0.2),
    inset 1px -1px 2px rgba(17, 20, 35, 0.2),
    inset -1px -1px 2px rgba(50, 59, 104, 0.9),
    inset 1px 1px 3px rgba(17, 20, 35, 0.9);
  border-radius: 10px;

  &__inner {
    padding: 20px;
    background: #212745;
    box-shadow: -2px 2px 4px rgba(18, 21, 37, 0.2),
      2px -2px 4px rgba(18, 21, 37, 0.2), -2px -2px 4px rgba(48, 57, 101, 0.9),
      2px 2px 5px rgba(18, 21, 37, 0.9),
      inset 1px 1px 2px rgba(48, 57, 101, 0.3),
      inset -1px -1px 2px rgba(18, 21, 37, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    display: flex;

    width: 80%;

    &-item {
      width: calc(100% / 4);
      max-width: 215px;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__add {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1600px) {
  .notification-filter {
  }
}
