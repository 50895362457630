@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("./Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("./Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("./Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("./Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("./Inter-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Noto Serif Tamil Slanted";
  font-display: swap;
  src: url("./NotoSerifTamilSlanted-Condensed.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Serif Tamil Slanted";
  font-display: swap;
  src: url("./NotoSerifTamilSlanted-Condensed.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
