@import "../../index.scss";

.save-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  outline: none;
  border: none;
  background: $blue_05;

  &__icon {
    display: block;
    width: 40px;
    height: 40px;

    background: url("../../assets/controls/save.svg") no-repeat;
    background-size: cover;
  }

  &:hover,
  &:focus,
  &:active {
    .save-button {
      &__icon {
        background: url("../../assets/controls/save-hover.svg") no-repeat;
        background-size: cover;
      }
    }
  }
}
