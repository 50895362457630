@import "../../index.scss";
@import "../../mixins.scss";

.admins {
  height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;

  @include custom-scroll;

  &__header {
    padding: 0 20px 10px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    @include gradient-text($purple_gradient);
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }

  &__search {
    margin-top: 10px;
    width: 415px;

    > div {
      width: 100%;
    }
  }

  &__body {
    min-height: calc(100% - 130px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pagination {
    margin-top: 30px;
  }
}

@media screen and (max-width: $media-tablet) {
  .admins {
    img {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
    }
  }
}
