@import "../../index.scss";
@import "../../mixins.scss";

.tips {
  &__text,
  &__title {
    @include gradient-text($purple_gradient);

    position: relative;
    top: 15px;
    padding: 0 39px;

    font-weight: 400;
    font-size: 50px;
    line-height: 78px;
  }

  &__title {
    font-weight: 600;
  }

  &__text {
    font-family: "Noto Serif Tamil Slanted";
    font-style: italic;
  }

  .image-left {
    position: relative;
    top: 115px;
  }

  &__choose {
    position: relative;
    height: 362px;
  }

  &__part {
    display: flex;

    .tips {
      &__text {
        top: 0;
      }
    }

    .image-left {
      position: relative;
      top: 40px;
    }
  }

  &__category {
    .tips {
      &__text {
        padding-left: 55px;
        font-size: 40px;
        line-height: 62px;
        font-family: "Noto Serif Tamil Slanted";
        font-style: italic;
      }
      &__title {
        position: absolute;
        padding-left: 0;
        max-width: 550px;
      }
    }
  }

  &__click {
    .image-left {
      width: 160px;
      height: 204px;
      top: 63px;
      left: -7px;
    }

    .tips {
      &__text {
        top: -35px;
        left: 22px;
      }
    }
  }

  &__save {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .image-right {
      position: relative;
    }

    .tips {
      &__text {
        top: 0;
        font-style: italic;
        font-weight: 400;
        font-size: 25px;
        line-height: 39px;
        padding-right: 10px;
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: $media-xl) {
  .tips {
    zoom: 75%;
  }
}

@media screen and (max-width: $media-tablet) {
  .tips {
    zoom: 50%;
  }
}

@media screen and (max-width: $media-mobile) {
  .tips {
    display: none;
  }
}
