@mixin gradient-text($color) {
  background: $color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin custom-scroll(
  $color-track: #265b62,
  $color-track-hover: #265b62,
  $color-thumb: #3ddde8,
  $width: 4px,
  $border-radius: 10px
) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $color-track;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-thumb;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-track-hover;
  }

  scrollbar-color: $color-thumb $color-track;
  scrollbar-width: $width;
}
