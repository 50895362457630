@import "../../index.scss";
.textarea {
  color: $white;
  margin: 0;
  text-align: left;
  font-size: 15px;
  height: 232px;
  overflow-y: auto;
  background: #212745;
  border: 3px solid #1d223f;
  box-shadow: -5px -8px 9px 5px #293054, 8px 9px 9px 6px #191e35,
    inset -4px -4px 5px #313b68, inset 4px 4px 8px #05070d;
  border-radius: 10px;
  scrollbar-color: $blue_text #265b62 !important;

  &::-webkit-scrollbar {
    background: #265b62;
    border-radius: 10px;
    width: 4px;
    margin-left: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 3px solid $blue_text;
  }

  display: block;
  outline: none;

  textarea {
    font-family: Inter;
  }
}
