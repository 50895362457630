@import "../../../index.scss";

.time-step {
  padding: 40px 40px;

  &__head {
    display: flex;
    justify-content: flex-end;

    > div:nth-child(1) {
      margin-left: auto;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-form {
      width: 100%;
      max-width: 1100px;
      margin-top: 111px;

      form {
        display: flex;
        flex-wrap: wrap;

        > div {
          width: calc((100% - 20px) / 3);
        }
        input {
          width: 100%;
        }
      }
    }

    .form-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      width: 300px;
      box-sizing: border-box;
      margin-bottom: 20px;

      &:nth-child(1) {
        margin-right: auto;
      }

      &:nth-child(2) {
        margin: 0 auto 20px;
      }

      &:nth-child(3) {
        margin-left: auto;
      }

      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24.2px;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: end;
        text-align: center;
        margin-bottom: 25px;
      }

      &__calendar {
        padding-top: 336px;
        margin-bottom: 20px;
        margin-top: auto;
      }

      > div {
        width: 100%;
      }

      .react-datepicker-popper {
        inset: unset !important;
        bottom: 54px !important;
        left: 0 !important;
        top: unset !important;
        right: unset !important;
        transform: unset !important;
        position: absolute !important;
        padding-top: 0;
        padding-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 1578px) {
  .time-step {
    &__body {
      &-form {
        margin-top: 50px;
      }
      .form-col {
        margin: 0 auto 20px;
      }
    }
  }
}
