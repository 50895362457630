@import "../../index.scss";

.autocopy {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 3px 7px;
  height: 24px;
  border-radius: 34px;
  background: $blue_gradient;
  cursor: pointer;
  width: 102px;
  box-sizing: border-box;
  &:hover,
  &:focus,
  &:active {
    span {
      color: $blue_text;
    }

    i {
      background: url("../../assets/controls/copy-hover.svg") no-repeat;
    }
  }

  span {
    display: block;
    margin-right: 5px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $white;
    font-family: Inter;
  }

  i {
    display: block;
    width: 15px;
    height: 17px;

    background: url("../../assets/controls/copy.svg") no-repeat;
    background-size: cover;
  }
}

.sm {
  padding: 0;
  background: transparent;
}
