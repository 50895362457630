@import "../../index.scss";

.modal-addUser {
  max-width: 506px;
  width: 100%;
  box-sizing: border-box;

  &__title {
    @include gradient-text($purple_gradient);
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }

  &__title-wrapper {
    margin-bottom: 20px;
  }

  &__form {
    width: 100%;
    box-sizing: border-box;
    color: $white;
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    input::placeholder {
      font-size: 13px;
      font-weight: 700;
      line-height: 30px;
      font-family: Inter;
      text-align: left;
    }

    span {
      display: inline;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    column-gap: 40px;
  }
}
