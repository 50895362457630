@import "../../index.scss";

.add-button {
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  background: none;
  padding: 0;

  &__icon {
    display: block;
    width: 100%;
    height: 100%;

    background: url("../../assets/controls/plus.svg") no-repeat;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.9;

    .add-button {
      &__icon {
        background: url("../../assets/controls/plus-hover.svg") no-repeat;
      }
    }
  }

  &.border {
    width: 71px;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #212745;
    border: 1px solid #1e2440;
    box-shadow: -2px 2px 4px rgba(13, 16, 28, 0.2),
      2px -2px 4px rgba(13, 16, 28, 0.2), -2px -2px 4px rgba(53, 62, 110, 0.9),
      2px 2px 5px rgba(13, 16, 28, 0.9),
      inset 1px 1px 2px rgba(53, 62, 110, 0.3),
      inset -1px -1px 2px rgba(13, 16, 28, 0.5);
    border-radius: 50%;

    .add-button {
      &__icon {
        width: 50px;
        display: block;
        height: 50px;
        background: url("../../assets/controls/plus.svg") no-repeat;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: 1px 1px 2px rgba(53, 62, 110, 0.3),
        -1px -1px 2px rgba(13, 16, 28, 0.5),
        inset -2px 2px 4px rgba(13, 16, 28, 0.2),
        inset 2px -2px 4px rgba(13, 16, 28, 0.2),
        inset -2px -2px 4px rgba(53, 62, 110, 0.9),
        inset 2px 2px 5px rgba(13, 16, 28, 0.9);
    }
  }
}
