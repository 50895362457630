@import "../../index.scss";
@import "../../mixins.scss";

.event-category {
  box-sizing: border-box;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #212745;
  padding: 10px;
  height: 100%;
  background: var(--event-item-color);
  border-radius: 14px;

  span {
    position: relative;
    z-index: 12;
    overflow: hidden;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: normal;
  }

  &.selected,
  &:hover {
    background: transparent;
    background: $main_bg_color;

    overflow: hidden;
    position: relative;
    z-index: 1;

    span {
      @include gradient-text(var(--event-item-color));
    }

    &::before {
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 4px;
      right: 4px;
      background: $main_bg_color;
      content: "";
      z-index: 0;
      border-radius: 14px;
    }

    &::after {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: var(--event-item-color);
      content: "";
      z-index: -1;
      border-radius: 14px;
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .event-category {
    font-size: 14px;
    padding: 10px;
  }
}
