.category-name {
  width: 100%;
}

.accordion-header > button:after {
  content: none;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 16px 20px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.actions {
  display: flex;
  gap: 20px;
}

.control-button {
  cursor: pointer;
}
