@import "../../index.scss";
@import "../../mixins.scss";

.modal-image {
  width: 80%;
  height: 85%;
  max-height: 967px;
  max-width: 1338px;
  position: relative;
  overflow: auto;
  padding: 10px 20px 20px !important;

  @include custom-scroll;

  &__btns {
    display: flex;
    justify-content: center;
    margin: 30px 0 20px;

    > *:not(:last-child) {
      margin-right: 50px;
    }
  }
}
