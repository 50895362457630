@import "../../index.scss";
@import "../../mixins.scss";

.commission {
  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__row {
    display: flex;
    align-items: center;
    column-gap: 20px;
    position: relative;

    & > div {
      & > span {
        color: $white;
        font-size: 10px;
        font-weight: 700;
        line-height: 15px;

        position: absolute;
        top: -11px;
      }

      & > div {
        width: 90px;
        min-height: 24px;
        padding: 6px 0;

        border-radius: 20px;
        box-shadow:
          -1px -1px 3px 0px #0d101ce5,
          1px 1px 2px 0px #353e6ee5,
          -1px 1px 2px 0px #0d101c33,
          1px -1px 2px 0px #0d101c33,
          1px 1px 2px 0px #0d101c80 inset,
          -1px -1px 2px 0px #353e6e4d inset;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        color: $white;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }
    }

    &:nth-child(2) > .commission__title {
      text-align: center;
    }
  }

  &__title {
    display: inline-block;
    width: 220px;

    color: $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}
