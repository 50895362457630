@import "../../index.scss";

.login {
  padding-top: 110px;
  margin: 20px;

  input {
    &::-webkit-input-placeholder {
      font-weight: 400;
    }
    &::-moz-placeholder {
      font-weight: 400;
    }
    &:-moz-placeholder {
      font-weight: 400;
    }
    &:-ms-input-placeholder {
      font-weight: 400;
    }
    &::-ms-input-placeholder {
      font-weight: 400;
    }
    &::placeholder {
      font-weight: 400;
    }

    height: 40px;
  }

  button {
    margin-top: 20px;
  }
}
