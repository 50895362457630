@import "../../index.scss";

.event {
  padding: 40px 40px;

  &__head {
    display: flex;
    margin-bottom: 37px;

    &-img {
      border: 2px solid #181d36;
      box-shadow: -4px -4px 11px #191e35, 4px 4px 14px #293054,
        inset -4px -4px 5px rgba(5, 7, 13, 0.49),
        inset 4px 4px 29px rgba(62, 72, 118, 0.45);
      border-radius: 10px;
      overflow: hidden;
      width: 469px;
      min-width: 469px;
      height: 193px;
      margin-right: 23px;
      // background: url("./../../assets/svg/img_bg.png") no-repeat center;
      img {
        border-radius: 8px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &-action {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    &-action-btns {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // margin-top: -20px;
      margin-bottom: 18px;
    }
  }
  &__data {
    max-width: calc(100% - 100px);
    &-row {
      &:not(:last-child) {
        border-bottom: 1px solid $blue_text;
      }
      padding: 13px 0;
      display: flex;
      &:last-child {
        margin-bottom: 22px;
      }
      .th {
        color: $white;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        width: 130px;
        margin-right: 15px;
      }
      .td {
        color: $white;
        font-weight: 700;
        font-size: 15px;
        width: calc(100% - 130px);
        display: flex;
        justify-content: space-between;
      }
      .td-btn {
        cursor: pointer;
        button {
          width: 30px;
          height: 30px;
        }
      }
      .td-text {
        width: calc(100% - 40px);
      }
    }
  }
  .btn {
    margin-right: 30px;

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(4) {
      margin-left: auto;
    }

    &.delete-btn {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 1420px) {
  .event {
    &__head {
      flex-direction: column;

      &-action {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .event {
    &__head {
      flex-direction: column;

      &-action {
        margin-top: 20px;

        .btn {
          &:nth-child(4) {
            margin-left: 0px;
          }
        }
      }
    }
  }
}
