@import "../../index.scss";

.translate-text {
  max-width: 687px;
  width: 100%;
  box-sizing: border-box;

  &__title {
    margin: 0;
    margin-bottom: 25px;

    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center !important;
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    > *:not(:last-child) {
      margin-right: 20px;
    }
  }
}
