@import "../../index.scss";

.animated-icon {
  position: relative;

  > div:nth-child(2) {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 11px;
    left: 7px;
    transition: all 0.3s ease;
    transform: rotate(0deg);
  }

  &.selected {
    > div:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
}
