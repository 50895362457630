@import "../../../index.scss";

.end-ref-step {
  padding: 40px 40px;

  &__head {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      color: $white;
      margin-bottom: 30px;
      text-align: center;
    }

    &-form {
      max-width: 905px;
      width: 100%;

      form {
        display: flex;
        flex-direction: column;
      }
    }

    .form-row {
      display: grid;
      grid-template-columns: 1.5fr;
      align-items: center;
      column-gap: 10px;
      max-width: 630px;
      width: 100%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: $media-hd) {
  .end-ref-step {
    padding: 40px;

    &__body {
      &-title {
        width: 100%;
        position: unset;
        text-align: center;
      }
    }
    .form-row {
      justify-content: center;
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .end-ref-step {
    padding: 20px;

    .form-row {
      justify-content: center;
    }
  }
}
