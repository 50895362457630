@import "../../index.scss";
@import "../../mixins.scss";

.dropdown {
  .label-wrap {
    display: flex;
    justify-content: center;

    .label {
      @include gradient-text($purple_gradient);
      @include custom-scroll;
      display: inline-block;
      max-width: 179px;
      text-align: center;
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 31px;
      font-family: "Noto Serif Tamil Slanted";
    }

    .form-label {
      font-size: 15px;
      font-weight: 400;
      line-height: normal;
    }
  }

  font-weight: 400;
  font-size: 25px;
  line-height: 30px;

  &.open {
    :global {
      [class*="-indicatorContainer"] {
        transform: rotate(180deg);
      }

      [class*="-control"] {
        box-shadow: inset -5px 5px 10px rgba(18, 21, 37, 0.2),
          inset 5px -5px 10px rgba(18, 21, 37, 0.2),
          inset -5px -5px 10px rgba(48, 57, 101, 0.9),
          inset 5px 5px 13px rgba(18, 21, 37, 0.9);
      }
    }
  }

  &__wrap {
    &:hover {
      :global {
        [class*="-control"] {
          box-shadow: inset -5px 5px 10px rgba(18, 21, 37, 0.2),
            inset 5px -5px 10px rgba(18, 21, 37, 0.2),
            inset -5px -5px 10px rgba(48, 57, 101, 0.9),
            inset 5px 5px 13px rgba(18, 21, 37, 0.9);
        }
      }
    }

    [class*="-indicatorContainer"] {
      svg {
        width: 20px;
        height: 11px;
        fill: transparent;
        transform: rotate(180deg);
      }
    }

    :global {
      [class*="-container"] {
        background: transparent;
        border: none;
        width: 244px;
      }

      [class*="-control"] {
        height: 54px;
        background: linear-gradient(135deg, #232948 0%, #1f2542 100%);
        box-shadow: -3px 3px 6px rgba(13, 16, 28, 0.2),
          3px -3px 6px rgba(13, 16, 28, 0.2),
          -3px -3px 6px rgba(53, 62, 110, 0.9),
          3px 3px 8px rgba(13, 16, 28, 0.9),
          inset 1px 1px 2px rgba(53, 62, 110, 0.3),
          inset -1px -1px 2px rgba(13, 16, 28, 0.5);

        > * {
          height: 54px;
        }

        input {
          position: absolute;
        }
      }
    }
  }

  &__form-wrap {
    [class*="-indicatorContainer"] {
      svg {
        width: 20px;
        height: 11px;
        fill: transparent;
        transform: rotate(180deg);
      }
    }

    :global {
      [class*="-container"] {
        background: transparent;
        border: none;
      }

      [class*="-control"] {
        justify-content: space-between;
        background-color: $main_bg_color;
        border: 1px solid #3ddde8;
        border-radius: 52px;
        padding: 3px 20px;
        min-height: 28px;
        width: 350px;

        [class*="-IndicatorsContainer"] {
          align-self: center;
        }

        [class*="-ValueContainer"] {
          padding: 0;
        }

        &:hover {
          cursor: pointer;
          border: 1px solid #3ddde8;
        }

        input {
          position: absolute;
        }
      }
    }
  }
}

.form-dropdown {
  font-size: 15px;
  font-weight: 400;
  line-height: normal;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
