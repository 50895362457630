@import "../../index.scss";

.modal-warning {
  max-width: 509px;
  width: 100%;
  box-sizing: border-box;

  &.addWalletAddress,
  &.addWalletAddressSuccess {
    max-width: 457px;
  }

  &.isQuestion {
    max-width: 460px;

    .modal-warning {
      &__stop {
        width: 90px;
        height: 90px;
        margin-top: 10px;
        margin-bottom: 30px;
      }

      &__title {
        padding: 0 10px;
      }

      &__btns {
        display: flex;
        justify-content: center;
        margin: 30px 0 10px;
      }
    }
  }

  &.isTwoFactor {
    .modal-warning {
      &__title {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 20px;
      }

      &__text {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;

        margin-bottom: 40px;
      }
    }
  }

  &.addWalletAddress {
    .modal-warning {
      &__title,
      &__text {
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom: 20px;
      }

      &__text {
        margin-bottom: 40px;
      }
    }
  }

  &.addWalletAddressSuccess {
    .modal-warning {
      &__title,
      &__text {
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom: 40px;
      }

      &__text {
        width: 397px;
        word-wrap: break-word;
        margin-bottom: 40px;
        font-size: 20px;
      }
    }
  }

  &__stop {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: 0 auto 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 33px;
    color: $white;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    display: inline-block;

    span {
      display: inline;
    }
  }

  &__success-text {
    color: $green_02;
  }

  &__error-text {
    color: $red;
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px;

    > *:not(:last-child) {
      margin-right: 50px;
    }
  }
}
