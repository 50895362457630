@import "../../index.scss";

.modal {
  width: 1374px;
  min-height: 681px;
  max-height: 95vh;

  padding: 20px !important;

  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: -10px -10px 25px 0px rgba(13, 16, 28, 0.9) inset,
    10px 10px 20px 0px rgba(53, 62, 110, 0.9) inset,
    -10px 10px 20px 0px rgba(13, 16, 28, 0.2) inset,
    10px -10px 20px 0px rgba(13, 16, 28, 0.2) inset,
    1px 1px 2px 0px rgba(13, 16, 28, 0.5),
    -1px -1px 2px 0px rgba(53, 62, 110, 0.3);

  &__header {
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    column-gap: 51px;

    .modal__btn:last-child {
      margin-left: auto;
    }
  }

  &__btn {
    button {
      svg {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__date-btn {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: linear-gradient(135deg, #232948 0%, #1f2542 100%);
    box-shadow: 3px 3px 8px 0px rgba(13, 16, 28, 0.9),
      -3px -3px 6px 0px rgba(53, 62, 110, 0.9),
      3px -3px 6px 0px rgba(13, 16, 28, 0.2),
      -3px 3px 6px 0px rgba(13, 16, 28, 0.2),
      -1px -1px 2px 0px rgba(13, 16, 28, 0.5) inset,
      1px 1px 2px 0px rgba(53, 62, 110, 0.3) inset;

    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;

    padding: 12px 24px;
    margin: 0;
    width: 245px;

    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &__table {
    max-height: 85%;

    &-total {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;

      margin: 10px 40px 20px auto;
      display: flex;
      justify-content: end;
      align-items: center;
      column-gap: 20px;

      span:first-child {
        display: inline-block;
        width: 177px;
      }
    }
  }
}
