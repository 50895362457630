@import "../../../index.scss";

.image-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px;
  background: #212745;
  border-radius: 15px;
  box-shadow: $cards_shadow;
  width: 100%;
  height: 100%;

  &__img {
    height: 100%;
    height: 190px;
    border-radius: 8px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      height: 190px;
    }
  }

  &__desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    position: relative;

    &-title {
      position: absolute;
      width: 44%;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $white;
      display: block;
      margin-right: 15px;

      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      white-space: normal;
    }

    button {
      margin-top: 0 !important;
    }

    &-tools {
      display: flex;
      flex-wrap: nowrap;
      margin-left: auto;

      > *:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
}
