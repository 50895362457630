@import "../../../index.scss";
@import "../../../mixins.scss";

.deleted-event {
  position: relative;
  height: calc(100vh - 108px);

  .content {
    padding-top: 5px;
    display: flex;
    height: 100%;
    width: 100%;

    &__tips {
      padding-top: 40px;
      padding-left: 57px;
    }

    &__page {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px;
      @include custom-scroll;
    }
  }

  &__img {
    img {
      right: 80px;
      position: absolute;
      bottom: 20px;
    }
  }
}

@media screen and (max-width: $media-hd) {
  .deleted-event {
    &__img {
      img {
        width: 300px;
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: $media-tablet) {
  .deleted-event {
    &__img {
      img {
        width: 250px;
        height: 250px;
      }
    }
  }
}

@media screen and (max-width: $media-tablet-small) {
  .deleted-event {
    &__img {
      display: none;
    }
  }
}
