@import "../../index.scss";

.modal {
  width: 938px;
  min-height: 582px;
  max-height: 90vh;

  padding: 20px !important;

  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: -10px -10px 25px 0px rgba(13, 16, 28, 0.9) inset,
    10px 10px 20px 0px rgba(53, 62, 110, 0.9) inset,
    -10px 10px 20px 0px rgba(13, 16, 28, 0.2) inset,
    10px -10px 20px 0px rgba(13, 16, 28, 0.2) inset,
    1px 1px 2px 0px rgba(13, 16, 28, 0.5),
    -1px -1px 2px 0px rgba(53, 62, 110, 0.3);

  &__header {
    margin-bottom: 20px;
  }

  &__title {
    @include gradient-text($purple_gradient);
    font-weight: 700;
    font-size: 25px;
    line-height: normal;

    margin-bottom: 20px;
  }

  &__subtitle {
    display: flex;
    column-gap: 59px;
    align-items: center;
  }

  &__action-bar {
    display: flex;
    column-gap: 33px;
  }

  &__btn {
    button {
      svg {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__close-btn {
    margin-left: auto;

    button {
      svg {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__date-btn {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: linear-gradient(135deg, #232948 0%, #1f2542 100%);
    box-shadow: 3px 3px 8px 0px rgba(13, 16, 28, 0.9),
      -3px -3px 6px 0px rgba(53, 62, 110, 0.9),
      3px -3px 6px 0px rgba(13, 16, 28, 0.2),
      -3px 3px 6px 0px rgba(13, 16, 28, 0.2),
      -1px -1px 2px 0px rgba(13, 16, 28, 0.5) inset,
      1px 1px 2px 0px rgba(53, 62, 110, 0.3) inset;

    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;

    padding: 12px 24px;
    margin: 0;
    width: 245px;

    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &__table {
    height: 418px;
    max-height: 83%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 5px;
      background: #3ddde8;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #265b62;
      border-radius: 10px;
    }
  }
}

.expanded-table {
  height: min-content;
}

.date-wrapper {
  input {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: linear-gradient(135deg, #232948 0%, #1f2542 100%);
    box-shadow: 3px 3px 8px 0px rgba(13, 16, 28, 0.9),
      -3px -3px 6px 0px rgba(53, 62, 110, 0.9),
      3px -3px 6px 0px rgba(13, 16, 28, 0.2),
      -3px 3px 6px 0px rgba(13, 16, 28, 0.2),
      -1px -1px 2px 0px rgba(13, 16, 28, 0.5) inset,
      1px 1px 2px 0px rgba(53, 62, 110, 0.3) inset;

    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    padding: 12px 24px;
    margin: 0;
    width: 245px;
  }
}
