@import "../../../index.scss";
@import "../../../mixins.scss";

.event {
  position: relative;
  height: calc(100vh - 108px);
  overflow-y: auto;
  @include custom-scroll;

  .content {
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  &__head {
    display: flex;
    &-img {
      border: 2px solid #181d36;
      box-shadow: -4px -4px 11px #191e35, 4px 4px 14px #293054,
        inset -4px -4px 5px rgba(5, 7, 13, 0.49),
        inset 4px 4px 29px rgba(62, 72, 118, 0.45);
      border-radius: 10px;
      overflow: hidden;
      width: 469px;
      height: 193px;
      margin-right: 23px;
      background: url("./../../../assets/svg/img_bg.png") no-repeat center;
      img {
        border-radius: 8px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &-action {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-action-btns {
      display: flex;
      align-items: center;
      margin-top: -20px;
    }
  }
  &__data {
    max-width: calc(100% - 145px);
    &-row {
      border-bottom: 1px solid $blue_text;
      padding: 13px 0;
      display: flex;
      &:last-child {
        margin-bottom: 22px;
      }
      .th {
        color: $white;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        width: 130px;
        margin-right: 15px;
      }
      .td {
        color: $white;
        font-weight: 700;
        font-size: 15px;
        width: calc(100% - 130px);
        display: flex;
        justify-content: space-between;
      }
      .td-btn {
        cursor: pointer;
      }
      .td-text {
        width: calc(100% - 40px);
      }
    }
  }
  .btn {
    margin-right: 30px;
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $media-hd) {
  .unpublished-event {
  }
}

@media screen and (max-width: $media-tablet) {
  .unpublished-event {
  }
}

@media screen and (max-width: $media-tablet-small) {
  .unpublished-event {
  }
}
