@import "../../index.scss";
@import "../../mixins.scss";

.statByEvent {

  h1 {
    color: white;
  }

  .accordionWrapper {
    max-height: 800px;
    overflow-y: auto;
  }

  .tableContainer {
    max-height: 300px; // Ограничиваем высоту таблицы
    overflow-y: auto;  // Вертикальная прокрутка
    overflow-x: auto;  // Горизонтальная прокрутка, если таблица слишком широкая
    border: 1px solid #4e4e6d;
    border-radius: 10px;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    color: #f5f5f5;

    th, td {
      border: 1px solid #4e4e6d;
      padding: 10px;
      text-align: left;
    }

    th {
      background-color: #2a2a40;
    }

    tr:nth-child(even) {
      background-color: #2a2a40;
    }
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .pageTitle {
    color: white; // Белый текст для заголовка
  }

  .accordionWrapper {
    background-color: #1b1b2f; // Темный фон для общего контейнера
    border-radius: 10px;
    padding: 10px;
  }

  .tableContainer {
    background-color: #1b1b2f; // Темный фон для контейнера с таблицами
    border: 1px solid #4e4e6d; // Обводка для контейнера
    padding: 10px;
    margin-bottom: 20px;

    h3 {
      color: white; // Белый текст для заголовков таблиц
    }

    .table {
      width: 100%;
      border-collapse: collapse;
      background-color: #1b1b2f;

      th, td {
        border: 1px solid #4e4e6d; // Цвет обводки ячеек
        padding: 10px;
        text-align: left;
      }

      th {
        color: #f5f5f5; // Белый цвет текста в заголовках таблицы
        background-color: #2a2a40; // Темный фон для заголовков таблицы
      }

      td {
        color: #f5f5f5; // Белый цвет текста в ячейках
        background-color: #1b1b2f; // Темный фон для ячеек
      }

      tr:nth-child(even) {
        background-color: #2a2a40; // Цвет для четных строк
      }

      tr:hover {
        background-color: #343456; // Цвет при наведении на строку
      }
    }
  }
}



.balance {
  height: 100vh;
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;

  @include custom-scroll;

  .header {
    padding: 0 20px 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .body {
    min-height: calc(100% - 130px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .title {
    @include gradient-text($purple_gradient);
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }

  .search {
    margin-top: 10px;
    width: 100%;
    max-width: 415px;

    > div {
      width: 100%;
    }
  }

  .pagination {
    margin-top: 30px;
  }
}

@media screen and (max-width: $media-tablet) {
  .balance {
    img {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
    }
  }
}
