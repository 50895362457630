@import "../../index.scss";

.steps-nav {
  height: 100%;
  padding: 20px 15px;
  position: relative;

  &:after {
    position: absolute;
    right: 0;
    top: 2px;
    content: "";
    width: 5px;
    height: 100%;
    box-shadow: $menu_line_shadow;
  }

  &__list {
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 108px - 45px - 73px);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #265b62;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #3ddde8;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #3ddde870;
    }

    scrollbar-color: #3ddde8 #265b62;
    scrollbar-width: 4px;

    &-item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__cancel {
    margin-top: 25px;
  }
}
