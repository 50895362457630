@import "../../index.scss";

.menu {
  position: relative;
  z-index: 1;
  width: 315px;
  min-width: 315px;
  height: 100vh;
  background: #252c4f;
  box-shadow: 21px 0px 13px -5px #191e35, -9px -7px 17px #293054,
    inset 8px 8px 10px -11px rgba(14, 21, 45, 0.49),
    inset -13px -18px 12px -7px rgba(20, 26, 54, 0.45);

  &-icon {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &-upper {
    position: relative;
    z-index: 20;
    box-shadow: 21px 0px 13px -5px #191e35, -9px -7px 17px #293054,
      inset 8px 8px 10px -11px rgb(14 21 45 / 49%),
      inset -13px -18px 12px -7px rgb(20 26 54 / 45%);
    justify-content: flex-start;
    display: flex;
    // flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 88px;
    padding: 10px 0;
    background-color: $main_bg_color;

    &-button {
      position: absolute;
      bottom: -5px;
      width: 100%;
      height: 5px;
      background: #212745;
      box-shadow: 2px 2px 3px #191e35, -2px -2px 5px #2c345c,
        inset -2px -2px 2px rgba(5, 7, 13, 0.49),
        inset 2px 2px 2px rgba(62, 72, 118, 0.45);
    }
  }

  &-content {
    height: calc(100% - 109px);
  }
}

.content {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: $media-notebook) {
  .menu {
    width: 20%;
    min-width: unset;
  }
}
