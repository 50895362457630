.edit-category {
  height: 90vh;
  width: 100%;
  padding: 20px 20px;
  overflow-y: auto;

  h2 {
    color: white;
  }

  .name-actions-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin: 20px;
  }

  .add-button {
    margin: 20px 0px;
    cursor: pointer;
  }

  .save-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
    gap: 20px;
    background: transparent;
  }
  
}
