@import "../../index.scss";
@import "../../mixins.scss";

.wrapperInputs {
  display: flex;
  justify-content: center;
  .wrapperTwoInputs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 56px;
    background: #233753;
    background-blend-mode: overlay, normal;
    border-radius: 10px;
    border: 1px solid $blue_01;
    margin-right: 16px;
    @supports (backdrop-filter: blur(20px)) {
      backdrop-filter: blur(20px);
      background: linear-gradient(
        101.35deg,
        rgba(57, 198, 212, 0.12) 0.3%,
        rgba(57, 198, 212, 0) 109.42%
      );
    }
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 576px) {
      width: 50px;
      height: 36px;
      margin-right: 10px;
    }
  }
  .number {
    // width: 27px;
    height: 46px;
    border-radius: 12px;
    border: none;
    font-size: 25px;
    line-height: 150%;
    text-align: center;
    color: $white;
    margin-right: 10px;
    background-color: transparent;
    outline: none;
    &::placeholder {
      color: $white;
    }
    @media (max-width: 576px) {
      width: 15px;
      height: 34px;
      font-size: 15px;
      border-radius: 8px;
      margin-right: 5px;
    }
    &:last-child {
      margin-right: 0;
    }

    //&:focus-visible{
    //  outline: none;
    //}
  }
  &.size-6 {
    .wrapperTwoInputs {
      width: 119px;
      @media (max-width: 576px) {
        width: 60px;
      }
    }
    // .number {
    //   width: 20px;
    // }
  }
}

.sizing {
  @media (max-width: 576px) {
    width: 94px !important;
    height: 60px !important;
  }
}

.flex {
  @media (max-width: 576px) {
    display: flex;
    justify-content: space-between;
  }
}
