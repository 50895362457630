@import "../../index.scss";
@import "../../mixins.scss";

.modal-two-factor {
  max-width: 509px;
  width: 100%;
  box-sizing: border-box;

  &__title-wrapper {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
  }

  &__title {
    color: $white;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    span {
      display: block;
      margin-bottom: 20px;
    }
  }

  &__btns-wrapper {
    display: flex;
    justify-content: center;
    column-gap: 40px;
  }
}

.content {
  &__text {
    margin-bottom: 20px;
  }
}

.links-container {
  display: flex;
  column-gap: 40px;
  margin-bottom: 20px;
}

.qr {
  &__container {
    width: 429px;
    margin-bottom: 20px;
    padding: 20px 0;

    display: flex;
    flex-direction: column;

    border-radius: 10px;
    box-shadow: 2px 2px 5px 0px rgba(13, 16, 28, 0.9) inset, -2px -2px 4px 0px rgba(53, 62, 110, 0.9) inset,
      2px -2px 4px 0px rgba(13, 16, 28, 0.2) inset, -2px 2px 4px 0px rgba(13, 16, 28, 0.2) inset,
      -1px -1px 2px 0px rgba(13, 16, 28, 0.5), 1px 1px 2px 0px rgba(53, 62, 110, 0.3);
  }

  &__image-wrapper {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }

  &__text {
    margin-bottom: 12px !important;
  }
}

.code-container {
  margin-bottom: 30px;
}

.code {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }

  &__text {
    color: $white;
    font-size: 13px;
    font-weight: 500;
    line-height: 150%; /* 19.5px */
    background: none;
    border: none;
    padding: 0;

    margin: 0 !important;
    width: fit-content;
    max-width: 349px;
  }
}

.copy-wrapper {
  cursor: pointer;
}
