@import "../../index.scss";
@import "../../mixins.scss";

.crypto {
  padding: 14px 20px 67px;
  height: 100vh;
  overflow-y: scroll;
  @include custom-scroll;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 12px;

    &--title {
      margin: 0;
      @include gradient-text($purple_gradient);
      font-size: 30px;
      font-weight: 700;
      line-height: normal;
    }

    &--balance-wrapper {
      display: flex;
      align-items: center;
      column-gap: 25px;
    }

    &--balance {
      box-sizing: border-box;

      padding: 10px;
      width: 324px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      background: #212745;
      box-shadow: 1px 1px 3px 0px rgba(13, 16, 28, 0.9) inset, -1px -1px 2px 0px rgba(53, 62, 110, 0.9) inset,
        1px -1px 2px 0px rgba(13, 16, 28, 0.2) inset, -1px 1px 2px 0px rgba(13, 16, 28, 0.2) inset,
        -1px -1px 2px 0px rgba(13, 16, 28, 0.5), 1px 1px 2px 0px rgba(53, 62, 110, 0.3);

      color: $white;
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;

      span:first-child {
        width: 150px;
        text-align: center;
      }
    }

    &--google {
      display: flex;
      align-items: center;
      column-gap: 25px;

      div:first-child {
        width: 57px;
        height: 57px;
      }

      span {
        color: $white;
        font-size: 16px;
        font-weight: 700;
        line-height: 150%; /* 24px */

        max-width: 183px;
        text-align: center;
      }
    }
  }

  &__wallet {
    border-radius: 20px;
    background: #212745;
    box-shadow: -1px -1px 3px 0px rgba(13, 16, 28, 0.9), 1px 1px 2px 0px rgba(53, 62, 110, 0.9),
      -1px 1px 2px 0px rgba(13, 16, 28, 0.2), 1px -1px 2px 0px rgba(13, 16, 28, 0.2),
      1px 1px 2px 0px rgba(13, 16, 28, 0.5) inset, -1px -1px 2px 0px rgba(53, 62, 110, 0.3) inset;

    padding: 10px;
    margin-bottom: 40px;
  }

  .wallet {
    &__container {
      border-radius: 20px;
      background: #212745;
      box-shadow: 1px 1px 3px 0px rgba(13, 16, 28, 0.9), -1px -1px 2px 0px rgba(53, 62, 110, 0.9),
        1px -1px 2px 0px rgba(13, 16, 28, 0.2), -1px 1px 2px 0px rgba(13, 16, 28, 0.2),
        -1px -1px 2px 0px rgba(13, 16, 28, 0.5) inset, 1px 1px 2px 0px rgba(53, 62, 110, 0.3) inset;

      padding: 20px;

      // display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      margin: 0;
      color: $blue_text;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;

      margin-bottom: 20px;
    }

    &__address-wrapper {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;

      .address {
        &__title {
          margin: 0;
        }
      }
    }

    &__address {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      margin-bottom: 20px;
    }

    &__amount-wrapper {
      display: flex;
      column-gap: 100px;

      & > div {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    }

    &__input-container {
      max-width: 457px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      input {
        margin: 0;
      }

      .input {
        &__btn-wrapper {
          display: flex;
          column-gap: 40px;
          justify-content: center;
        }

        &__wrapper {
          display: flex;
          column-gap: 105px;
        }
      }
    }
  }

  &__table {
    border-radius: 20px;
    background: #212745;
    box-shadow: -1px -1px 3px 0px rgba(13, 16, 28, 0.9), 1px 1px 2px 0px rgba(53, 62, 110, 0.9),
      -1px 1px 2px 0px rgba(13, 16, 28, 0.2), 1px -1px 2px 0px rgba(13, 16, 28, 0.2),
      1px 1px 2px 0px rgba(13, 16, 28, 0.5) inset, -1px -1px 2px 0px rgba(53, 62, 110, 0.3) inset;

    padding: 10px;
  }

  .table {
    &__info-container {
      border-radius: 20px;
      background: #212745;
      box-shadow: 1px 1px 3px 0px rgba(13, 16, 28, 0.9), -1px -1px 2px 0px rgba(53, 62, 110, 0.9),
        1px -1px 2px 0px rgba(13, 16, 28, 0.2), -1px 1px 2px 0px rgba(13, 16, 28, 0.2),
        -1px -1px 2px 0px rgba(13, 16, 28, 0.5) inset, 1px 1px 2px 0px rgba(53, 62, 110, 0.3) inset;

      padding: 18px 40px 18px 60px;

      display: flex;
      align-items: center;
      column-gap: 60px;
    }

    &__info {
      border-radius: 10px;
      background: #212745;
      box-shadow: 1px 1px 3px 0px rgba(13, 16, 28, 0.9) inset, -1px -1px 2px 0px rgba(53, 62, 110, 0.9) inset,
        1px -1px 2px 0px rgba(13, 16, 28, 0.2) inset, -1px 1px 2px 0px rgba(13, 16, 28, 0.2) inset,
        -1px -1px 2px 0px rgba(13, 16, 28, 0.5), 1px 1px 2px 0px rgba(53, 62, 110, 0.3);

      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;

      display: flex;
      flex-direction: column;
      row-gap: 10px;
      align-items: center;

      width: 220px;
      padding: 10px 0;
    }

    &__addBtn {
      margin-left: auto;
    }

    &__wrapper {
      height: 37vh;
      overflow-y: scroll;
      @include custom-scroll;
    }
  }
}
