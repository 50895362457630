@import "../../index.scss";

.step-save {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  > *:nth-child(2) {
    margin-left: 10px;
  }
}

@media screen and (max-width: $media-tablet) {
  .step-save {
    :global {
      .tips {
        zoom: 100%;
      }
    }
  }
}
