@import "../../index.scss";
@import "../../mixins.scss";

.toggles {
  box-shadow:
    1px 1px 3px 0px #0d101ce5 inset,
    -1px -1px 2px 0px #353e6ee5 inset,
    1px -1px 2px 0px #0d101c33 inset,
    -1px 1px 2px 0px #0d101c33 inset,
    -1px -1px 2px 0px #0d101c80,
    1px 1px 2px 0px #353e6e4d;

  border-radius: 10px;
  padding: 20px;

  color: $white;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;

  &__title {
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__row {
    display: flex;
    column-gap: 40px;
    align-items: center;
    justify-content: end;
  }
}
