@import "../../index.scss";

.button {
  text-align: center;

  button {
    background-color: $main_bg_color;
    box-shadow:
      11px 9px 8px 4px #191e35,
      -6px -7px 14px 2px #2c345c,
      inset -4px -4px 5px -1px rgba(5, 7, 13, 0.49),
      inset 4px 4px 3px -19px rgba(62, 72, 118, 0.45);
    border-radius: 10px;
    // width: 100%;
    color: #3ddde8;
    padding: 0 24px;
    // margin-top: 20px;
    font-weight: 700;
    font-family: Inter;
    font-size: 25px;
    line-height: 30px;
    height: 54px;
    border: none;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      background: #444754;
      box-shadow:
        4px 7px 8px 1px #191e35,
        -6px -7px 14px -2px #2c345c,
        inset -4px -4px 5px -1px rgba(5, 7, 13, 0.49),
        inset 4px 4px 29px -19px rgba(62, 72, 118, 0.45);
    }

    &:focus-visible {
      box-shadow: $inner_shadow;
    }
    &:focus {
      outline: 2px;
    }
    &:active,
    &:hover {
      outline: 2px;
      box-shadow: $inner_shadow;
    }
  }

  &.customBox {
    button {
      box-shadow:
        1px 1px 3px 0px #0d101ce5,
        -1px -1px 2px 0px #353e6ee5,
        1px -1px 2px 0px #0d101c33,
        -1px 1px 2px 0px #0d101c33,
        -1px -1px 2px 0px #0d101c80 inset,
        1px 1px 2px 0px #353e6e4d inset;

      &:hover {
        outline: 2px;
        box-shadow: $inner_shadow;
      }
    }
  }
}

.button-clear {
  button {
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    background: none;
    padding: 0;

    &:disabled {
      background: none;
      opacity: 0.5;
      cursor: default;
    }

    > * {
      width: 100%;
      height: 100%;
    }
  }
}

.edit-button,
.block-button,
.unblock-button,
.show-button,
.delete-button,
.time-button {
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  background: none;
  padding: 0;

  &__icon {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../../assets/controls/edit.png") no-repeat;
    background-size: contain;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.9;

    .edit-button {
      &__icon {
        background: url("../../assets/controls/edit.png") no-repeat;
        background-size: contain;
      }
    }
  }

  &:disabled {
    background: none;
    opacity: 0.5;
    cursor: default;
  }
}

.delete-button {
  &__icon {
    background: url("../../assets/controls/delete.svg") no-repeat;
    background-size: contain;
  }

  &:hover,
  &:focus,
  &:active {
    .delete-button {
      &__icon {
        background: url("../../assets/controls/delete.svg") no-repeat;
        background-size: contain;
      }
    }
  }
}

.show-button {
  &__icon {
    background: url("../../assets/controls/eye.png") no-repeat;
    background-size: contain;
  }

  &:hover,
  &:focus,
  &:active {
    .show-button {
      &__icon {
        background: url("../../assets/controls/eye.png") no-repeat;
        background-size: contain;
      }
    }
  }
}

.block-button {
  &__icon {
    background: url("../../assets/controls/block.png") no-repeat;
    background-size: contain;
  }

  &:hover,
  &:focus,
  &:active {
    .block-button {
      &__icon {
        background: url("../../assets/controls/block.png") no-repeat;
        background-size: contain;
      }
    }
  }
}

.unblock-button {
  &__icon {
    background: url("../../assets/controls/unblock.png") no-repeat;
    background-size: contain;
  }

  &:hover,
  &:focus,
  &:active {
    .unblock-button {
      &__icon {
        background: url("../../assets/controls/unblock.png") no-repeat;
        background-size: contain;
      }
    }
  }
}

.time-button {
  &__icon {
    background: url("../../assets/controls/time.svg") no-repeat;
    background-size: contain;
  }

  &:hover,
  &:focus,
  &:active {
    .block-button {
      &__icon {
        background: url("../../assets/controls/time.svg") no-repeat;
        background-size: contain;
      }
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .button {
    button {
      font-size: 20px;
    }
  }
}
