.modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-footer {
  display: flex !important;
  justify-content: space-between !important;
}

.buttons-wrapper {
  display: flex;
  gap: 5px;
}

.additional-button {
  display: flex !important;
  gap: 5px;
  color: white !important;
  align-items: center;
}

.action-button {
  color: white !important;
}
