@import "../../index.scss";

.filter-item {
  background: linear-gradient(135deg, #232948 0%, #1f2542 100%);
  box-shadow: -3px 3px 6px rgba(13, 16, 28, 0.2),
    3px -3px 6px rgba(13, 16, 28, 0.2), -3px -3px 6px rgba(53, 62, 110, 0.9),
    3px 3px 8px rgba(13, 16, 28, 0.9), inset 1px 1px 2px rgba(53, 62, 110, 0.3),
    inset -1px -1px 2px rgba(13, 16, 28, 0.5);
  padding: 12px 24px;
  max-width: 215px;
  box-sizing: border-box;
  cursor: pointer;

  &.active,
  &:hover,
  &:focus {
    background: #212745;
    box-shadow: inset -5px 5px 10px rgba(18, 21, 37, 0.2),
      inset 5px -5px 10px rgba(18, 21, 37, 0.2),
      inset -5px -5px 10px rgba(48, 57, 101, 0.9),
      inset 5px 5px 13px rgba(18, 21, 37, 0.9);
    border-radius: 10px;
  }

  &__inner {
    display: flex;
    align-items: center;
    column-gap: 10px;

    span {
      display: block;
      width: 100%;
      color: var(--filter-color, $white);
      text-align: center;
      font-size: 25px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .filter-item {
    &__inner {
      span {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: $media-hd) {
  .filter-item {
    &__inner {
      span {
        font-size: 16px;
      }
    }
  }
}
