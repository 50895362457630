@import "../../index.scss";

.box {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > li {
      width: calc((100% - 40px) / 3);
      margin-bottom: 20px;

      &:not(:nth-child(3n)) {
        margin-right: 20px;
      }

      > div {
        margin-right: 0;
        max-width: 100%;
        height: 100%;
      }
    }

    &-pagination {
      margin-top: 30px;
      margin-left: auto;
    }
  }

  &__empty-text {
    @include gradient-text($purple_gradient);
    width: 100%;
    margin-top: 200px;
    text-align: center;
    font-size: 44px;
  }
}

@media screen and (max-width: 1600px) {
  .box {
    &__list {
      > li {
        width: calc((100% - 20px) / 2);
        margin-right: 20px;

        &:nth-child(2n) {
          margin-right: 0px;
        }

        > div {
          max-width: 100%;
        }
      }
    }
  }
}
