@import "../../index.scss";
@import "../../mixins.scss";

.adress-wrapper {
  width: 600px;

  .adress {
    display: inline-flex;
    gap: 20px;
    color: $white;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
  }
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $blue_text;
  height: 44px;
  padding-left: 10px;
}

.row-title {
  width: 250px;
  font-size: 20px;
  font-weight: 700;
  color: $blue_text;
}

.quantity {
  font-size: 20px;
  font-weight: 700;
  color: $white;
}

.wallet-form {
  display: flex;
  gap: 50px;
  align-items: center;
  padding-top: 20px;
  padding-left: 10px;
  &_form {
    @extend .wallet-form;
    padding-top: 0;
  }

  button {
    width: 200px;
    height: 72px;
    box-shadow: 1px 1px 3px 0px rgba(13, 16, 28, 0.9), -1px -1px 2px 0px rgba(53, 62, 110, 0.9),
      1px -1px 2px 0px rgba(13, 16, 28, 0.2), -1px 1px 2px 0px rgba(13, 16, 28, 0.2),
      -1px -1px 2px 0px rgba(13, 16, 28, 0.5) inset, 1px 1px 2px 0px rgba(53, 62, 110, 0.3) inset;
    background-color: $main_bg_color;
    border-radius: 10px;
    color: $blue_text;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    &:hover {
      box-shadow: -1px -1px 3px 0px rgba(13, 16, 28, 0.9), 1px 1px 2px 0px rgba(53, 62, 110, 0.9),
        -1px 1px 2px 0px rgba(13, 16, 28, 0.2), 1px -1px 2px 0px rgba(13, 16, 28, 0.2),
        1px 1px 2px 0px rgba(13, 16, 28, 0.5) inset, -1px -1px 2px 0px rgba(53, 62, 110, 0.3) inset;
    }
  }
}

.wallet-imput {
  padding: 0;
  margin: 0;
  height: 36px;
  color: #a0a0a0;
  border: none;
  box-shadow: -1px -1px 3px 0px rgba(13, 16, 28, 0.9), 1px 1px 2px 0px rgba(53, 62, 110, 0.9),
    -1px 1px 2px 0px rgba(13, 16, 28, 0.2), 1px -1px 2px 0px rgba(13, 16, 28, 0.2),
    1px 1px 2px 0px rgba(13, 16, 28, 0.5) inset, -1px -1px 2px 0px rgba(53, 62, 110, 0.3) inset;

  &_adress {
    @extend .wallet-imput;
    width: 475px;
  }

  &_amount {
    @extend .wallet-imput;
    width: 185px;
  }
}

.errorMessage {
  color: $red;
  margin-top: 5px;
}
