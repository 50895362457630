.input {
  text-align: start;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#input-wrapper {
  width: 50px;
}
