@import "../../index.scss";
@import "../../mixins.scss";

.menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .menu-list {
    overflow-y: auto;
    height: calc(100vh - 230px);
    @include custom-scroll;

    .label {
      @include gradient-text($purple_gradient);

      display: flex;
      align-items: center;
      justify-content: center;

      text-align: left;
      padding: 20px 20px 5px;

      font-weight: 700;
      font-size: 25px;
      line-height: 30px;

      &:hover {
        @include gradient-text(#3ddde8);
      }
    }

    &__item {
      position: relative;
      padding-bottom: 5px;
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: $violet;
      cursor: pointer;

      .label {
        text-align: center;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        background: #212745;
        border-radius: 10px;
        height: 5px;
        box-shadow:
          2px 2px 3px #191e35,
          -2px -2px 5px #2c345c,
          inset -2px -2px 2px rgba(5, 7, 13, 0.49),
          inset 2px 2px 2px rgba(62, 72, 118, 0.45);
      }

      &.has-childrens.active {
        &:after {
          display: none;
        }
      }

      &.has-childrens {
        .label {
          padding: 20px 30px 5px;
        }
      }

      &.active {
        .label {
          @include gradient-text(#3ddde8);
        }
      }

      &.disabled {
        cursor: auto;

        .label {
          @include gradient-text(#667998);
        }
      }

      &-arrow {
        position: absolute;
        right: 19px;
        bottom: 10px;
      }
    }

    &__children {
      padding: 20px;
      border-radius: 10px;
      background: #212745;
      border: 3px solid #1d223f;
      box-shadow:
        -3px -4px 9px #293054,
        4px 7px 8px 1px #191e35,
        inset -4px -4px 5px #313b68,
        inset 4px 4px 8px #05070d;

      &-item {
        display: flex;
        align-items: center;
        height: 56px;
        position: relative;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: $violet;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        .label {
          @include gradient-text($violet);

          position: relative;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          padding: 0;

          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          padding-right: 30px;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid $violet;
          }
        }

        &.active {
          .label {
            @include gradient-text(#3ddde8);

            &:after {
              background: #3ddde8;
            }
          }
        }

        &:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            border-bottom: 1px solid #3ddde8;
          }
        }
      }
    }

    .menu-list {
      &__children-wrapp {
        max-height: 0;
        overflow: hidden;

        &.submenu-expanded {
          max-height: 1000vh;
        }
      }
    }
  }

  .menu-footer {
    padding: 15px 15px 10px;

    &__btns {
      display: flex;
      justify-content: space-between;
      padding: 5px 40px 10px;
      background: #212745;
      box-shadow:
        1px 1px 2px rgba(53, 62, 110, 0.3),
        -1px -1px 2px rgba(13, 16, 28, 0.5),
        inset -3px 3px 6px rgba(13, 16, 28, 0.2),
        inset 3px -3px 6px rgba(13, 16, 28, 0.2),
        inset -3px -3px 6px rgba(53, 62, 110, 0.9),
        inset 3px 3px 8px rgba(13, 16, 28, 0.9);
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .menu-wrapper {
    .menu-list {
      .label {
        font-size: 20px;
      }

      &__children {
        &-item {
          min-height: 56px;
          height: unset;
        }
        .label {
          font-size: 14px;

          &:after {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .menu-footer {
      padding: 15px 15px 10px;

      &__btns {
        padding: 5px 10px 10px;
      }
    }
  }
}
