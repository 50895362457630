@import "../../../index.scss";

.custom-timeinput {
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  &__range {
    margin: 0 30px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    input {
      width: 49px !important;
      height: 41px !important;
      border-radius: 9px;
      padding: 6px 8px !important;
      font-weight: 400;
      font-size: 25px;
      line-height: 29px;
      font-family: "Inter";
    }
    input:read-only {
      cursor: pointer;
    }

    .separator {
      display: block;
      margin: -5px 3px 0;
      font-size: 35px;
    }

    &-controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      > div {
        width: 20px;
        height: 13px;
      }

      > div:nth-child(1) {
        margin-bottom: 10px;

        background: url("../../../assets/arrowup.svg") no-repeat;
        background-size: contain;
      }

      > div:nth-child(2) {
        transform: rotate(180deg);
        background: url("../../../assets/arrowup.svg") no-repeat;
        background-size: contain;
      }
    }
  }

  .time-dropdown {
    position: absolute;
    bottom: -200px;
    z-index: 10;
    left: calc(0);
    width: 100%;
    max-height: 200px;
    background: #212745;
    overflow: hidden;
    &__inner {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      max-height: 200px;
      padding: 10px;
      width: 100%;

      background: linear-gradient(
        101.35deg,
        rgba(57, 198, 212, 0.12) 0.3%,
        rgba(57, 198, 212, 0) 109.42%
      );

      > div {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 10px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }

    &__item {
      cursor: pointer;
      margin: 5px 0;
      padding: 3px;
      border-radius: 8px;
      &.selected {
        background: #229dbb;
      }
    }
    border: 1px solid #3ddde8;
    border-radius: 10px;
  }
}
