@import "../../index.scss";

.modal-pin {
  max-width: 509px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    margin-bottom: 20px;
  }

  &__close-wrapper {
    cursor: pointer;
    margin-right: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__title {
    margin: 0;
    text-align: center !important;
    font-size: 25px !important;
  }

  &__content {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 40px;

    p {
      margin: 0;
      margin-bottom: 40px;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
  }
}
