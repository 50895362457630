@import "../../index.scss";

.home {
  height: 100vh;
  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    max-width: 1005px;
    max-height: 680px;
  }

  .header-wrapper {
    position: absolute;
    top: 40px;
    left: 30px;

    display: flex;
    align-items: center;
    column-gap: 40px;

    div:first-child {
      width: 57px;
      height: 57px;
    }

    span {
      color: $white;
      font-size: 16px;
      font-weight: 700;
      line-height: 150%; /* 24px */

      max-width: 183px;
      text-align: center;
    }
  }
}

@media screen and (max-width: $media-tablet) {
  .home {
    img {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
    }
  }
}
