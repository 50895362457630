@import "../../index.scss";

.login {
  height: 100vh;
  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    max-width: 1005px;
    max-height: 680px;
  }
}

@media screen and (max-width: $media-tablet) {
  .login {
    img {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
    }
  }
}
