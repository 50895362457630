@import "../../index.scss";
@import "../../mixins.scss";

.table-box {
  min-height: 250px;
  overflow-x: auto;
  @include custom-scroll;

  * {
    box-sizing: border-box;
  }

  background: #212745;
  box-shadow:
    1px 1px 2px rgba(50, 59, 104, 0.3),
    -1px -1px 2px rgba(17, 20, 35, 0.5),
    inset -1px 1px 2px rgba(17, 20, 35, 0.2),
    inset 1px -1px 2px rgba(17, 20, 35, 0.2),
    inset -1px -1px 2px rgba(50, 59, 104, 0.9),
    inset 1px 1px 3px rgba(17, 20, 35, 0.9);
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 0;

  table {
    margin-bottom: 10px;
    color: $white;
    caption-side: bottom;
    border-collapse: collapse;
    width: 100%;

    thead,
    thead > tr,
    th,
    tbody,
    td {
      border: none;
    }

    th,
    td {
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      font-family: Inter;
    }

    th {
      text-align: center;
      vertical-align: middle;
      padding: 10px;

      span {
        position: relative;
        display: inline-block;
      }
    }

    thead {
      th:nth-child(1),
      th:nth-child(3),
      th:nth-child(2),
      th:nth-child(4) {
        width: 7.8%;
      }
      th:nth-child(3) {
        width: 65%;
      }
    }

    tbody {
      tr {
        border: 1px solid $blue_text;
        &:last-child {
          border: 1px solid transparent;
        }
        border-left: none;
        border-right: none;
      }

      td {
        padding: 15px 10px;
        font-weight: 700;
        text-align: center;
      }

      td:nth-child(3) {
        // width: 65%;
      }

      > tr:not(.subrow):hover {
        box-shadow:
          1px 1px 2px rgba(53, 62, 110, 0.3),
          -1px -1px 2px rgba(13, 16, 28, 0.5),
          inset -4px 4px 8px rgba(13, 16, 28, 0.2),
          inset 4px -4px 8px rgba(13, 16, 28, 0.2),
          inset -4px -4px 8px rgba(53, 62, 110, 0.9),
          inset 4px 4px 10px rgba(13, 16, 28, 0.9);
      }

      .col-disabled {
        color: $white;
        background: #444754;
      }
      .status {
        > div {
          margin: 0 auto;
        }
      }

      .actions {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        column-gap: 19px;
        align-items: center;
      }
    }
  }

  &.users {
    table {
      thead {
        th:nth-child(1) {
          width: 4%;
        }
        th:nth-child(2) {
          width: 10%;
        }
        th:nth-child(3) {
          width: 10%;
        }
        th:nth-child(4) {
          width: 6%;
        }
        th:nth-child(5) {
          width: 6%;
        }
        th:nth-child(6) {
          width: 5%;
        }
        th:nth-child(7) {
          width: 5%;
        }
        th:nth-child(8) {
          width: 5%;
        }
      }

      tbody {
        tr {
          border-bottom: none;
        }
        td:nth-child(3) {
          width: 10%;
        }

        td {
          font-weight: 400;
        }

        .deleted {
          color: #a0a0a0;
        }
      }
    }

    .addition-info {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 0;
      overflow: hidden;

      &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        div {
          background: #204459;
          border-radius: 50px;
          padding: 5px 50px;
          width: 247px;
          text-align: center;
        }
      }

      span {
        display: block;
        width: 100%;
        max-width: 165px;
        text-align: center;
        margin-right: 104px;
      }
    }

    .expanded {
      border-bottom: none;

      .addition-info {
        padding: 23px 10px;
        height: auto;
      }
    }

    .subrow {
      border: none;
      td {
        text-align: left;
        padding: 0;
      }
    }

    .actions {
      > div:nth-child(3) {
        button:hover {
          position: relative;
          z-index: 1;
          &:after {
            position: absolute;
            content: "";
            width: 38px;
            height: 38px;
            z-index: -1;
            left: -4px;
            top: -4px;
            background: #2b8195;
            border-radius: 50%;
          }
        }
      }
    }

    .user-col.history {
      &.selected {
        button {
          svg path,
          svg {
            fill: #3ddde8;
          }
          svg line {
            stroke: #3ddde8;
          }
        }
      }
      button {
        &:hover {
          svg path,
          svg {
            fill: #3ddde8;
          }
          svg line {
            stroke: #3ddde8;
          }
        }
      }
    }
  }

  &.history {
    max-height: 790px;
    box-shadow: none;
    border: none;

    table {
      th,
      td {
        font-weight: 400;
        font-size: 11px;
        line-height: 150%;
        font-family: Inter;
      }

      thead {
        th {
          width: 11%;
        }
        th:nth-child(1) {
          width: 7%;
        }
        th:nth-child(9) {
          width: 8%;
          text-align: left;
        }
      }

      tbody {
        tr:last-child {
          border: 1px solid #3ddde8;
          border-left: none;
          border-right: none;
        }

        td:nth-child(3) {
          width: 11%;
        }

        td:nth-child(7),
        td:nth-child(8) {
          > div > span {
            display: inline-block;
            width: 85px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        td:nth-child(7) {
          > span {
            display: inline-block;
            width: 85px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        td {
          font-weight: 400;
          padding: 12px 10px;

          .green {
            color: #4db275;
            font-size: 14px;
          }

          .red {
            color: #eb4755;
            font-size: 14px;
          }
        }
      }
    }
  }

  &.balance {
    table {
      thead {
        th {
          &:nth-child(1) {
            width: 15%;
          }
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 15%;
          }
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 20%;
          }
        }
      }

      .col-form {
        > * {
          max-width: 200px;
          margin: 0 auto;
          font-weight: 700;
        }
      }
      td {
        font-weight: 400;
      }
      td:nth-child(3) {
        width: 15% !important;
      }
    }
  }

  &.hours {
    table {
      th,
      td {
        font-weight: 700;
        font-size: 15px;
        line-height: normal;
        font-family: Inter;
      }

      thead {
        th:nth-child(3) {
          width: 7.8%;
        }
      }

      tbody {
        td {
          padding: 10px;

          &:first-child {
            width: 17%;
          }
        }
      }
    }
  }

  &.actions {
    table {
      th,
      td {
        font-weight: 700;
        font-size: 15px;
        line-height: normal;
        font-family: Inter;
      }

      th {
        padding-top: 0;
      }

      tbody {
        td {
          padding: 10px;

          &:first-child {
            width: 20%;
          }

          &:last-child {
            display: inline-block;
            width: 673px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
          }
        }
      }
    }
  }

  &.crypto {
    box-shadow: none;
    table {
      th,
      td {
        font-weight: 700;
        font-size: 20px;
        line-height: normal;
        font-family: Inter;
      }

      thead {
        th:nth-child(1) {
          width: 4%;
        }
        th:nth-child(2) {
          width: 10%;
        }
        th:nth-child(3) {
          width: 10%;
        }
        th:nth-child(4) {
          width: 6%;
        }
        th:nth-child(5) {
          width: 6%;
        }
      }
    }
  }

  &.profit {
    box-shadow: none;
    table {
      th,
      td {
        font-weight: 400;
        font-size: 20px;
        line-height: normal;
        font-family: Inter;
      }

      th {
        font-weight: 700;
      }

      td:nth-child(2) {
        display: inline-block;
        overflow: hidden;
        text-align: start;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 682px;
      }

      thead {
        th:nth-child(1) {
          width: 18%;
        }
        th:nth-child(2) {
          width: 53%;
        }
        th:nth-child(3) {
          width: 17%;
        }
        th:nth-child(4) {
          width: 12%;
        }
      }
    }
  }

  &.mainWallet {
    box-shadow: none;
    table {
      th,
      td {
        font-weight: 400;
        font-size: 20px;
        line-height: normal;
        font-family: Inter;
      }

      th {
        font-weight: 700;
      }

      thead {
        th {
          width: 0;
        }
      }

      tbody {
        tr:last-child {
          border: 1px solid #3ddde8;
          border-left: none;
          border-right: none;
        }

        td:nth-child(1),
        td:nth-child(6),
        td:nth-child(7) {
          > span > div > p {
            display: inline-block;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        td:nth-child(2) {
          > span {
            display: inline-block;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &.childWallet {
    box-shadow: none;
    table {
      th,
      td {
        font-weight: 400;
        font-size: 20px;
        line-height: normal;
        font-family: Inter;
      }

      th {
        font-weight: 700;
      }

      thead {
        th {
          width: 0;
        }
      }

      tbody {
        tr:last-child {
          border: 1px solid #3ddde8;
          border-left: none;
          border-right: none;
        }

        td:nth-child(1),
        td:nth-child(7),
        td:nth-child(8) {
          > span > div > p {
            display: inline-block;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        td:nth-child(2) {
          > span {
            display: inline-block;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .copy {
    > span {
      display: flex;
      flex-wrap: nowrap;
      text-align: left;

      span {
        display: block;
        margin-left: 10px;
      }
    }
  }

  .done,
  .win {
    text-align: left;
    span {
      color: #4db275;
    }
  }

  .cancel,
  .fail {
    text-align: left;
    span {
      color: #eb4755;
    }
  }

  .pending {
    text-align: left;
    span {
      color: #ffd01f;
    }
  }
}
