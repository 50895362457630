@import "../../index.scss";
@import "../../mixins.scss";

.modal-transfer-summary {
  max-width: 457px;
  width: 100%;
  box-sizing: border-box;

  &__header-wrapper {
    position: relative;
    margin-bottom: 10px;
    text-align: center;
  }

  &__arrow {
    position: absolute;
    cursor: pointer;
  }

  &__header {
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    max-width: 332px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
}

.content {
  &__text-wrapper {
    display: flex;
    column-gap: 10px;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__amount-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    padding: 17px 10px;
    padding-right: 5px;
    border-radius: 10px;
    box-shadow: 1px 1px 3px 0px rgba(13, 16, 28, 0.9) inset, -1px -1px 2px 0px rgba(53, 62, 110, 0.9) inset,
      1px -1px 2px 0px rgba(13, 16, 28, 0.2) inset, -1px 1px 2px 0px rgba(13, 16, 28, 0.2) inset,
      -1px -1px 2px 0px rgba(13, 16, 28, 0.5), 1px 1px 2px 0px rgba(53, 62, 110, 0.3);
  }

  &__line {
    display: flex;
    column-gap: 10px;

    .content__text:first-child {
      width: 121px;
      text-align: left;
    }
  }

  &__checkmark {
    margin: 14px 0 34px;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 20px;
  }

  &__info-text {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    max-width: 397px;
  }
}

.yellow {
  color: #ffd01f;
  display: block;
  text-align: center;
}
