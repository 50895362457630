@import "../../index.scss";
@import "../../mixins.scss";

.modal {
  max-width: 506px;
  width: 100%;
  height: 80%;
  box-sizing: border-box;
  padding: 0 0 15px !important;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__close {
    button {
      cursor: pointer;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #212745;
      border: 1px solid #1e2440;
      box-shadow: -2px 2px 4px rgba(13, 16, 28, 0.2),
        2px -2px 4px rgba(13, 16, 28, 0.2), -2px -2px 4px rgba(53, 62, 110, 0.9),
        2px 2px 5px rgba(13, 16, 28, 0.9),
        inset 1px 1px 2px rgba(53, 62, 110, 0.3),
        inset -1px -1px 2px rgba(13, 16, 28, 0.5);
      border-radius: 50%;

      &:focus,
      &:active {
        box-shadow: 1px 1px 2px rgba(53, 62, 110, 0.3),
          -1px -1px 2px rgba(13, 16, 28, 0.5),
          inset -2px 2px 4px rgba(13, 16, 28, 0.2),
          inset 2px -2px 4px rgba(13, 16, 28, 0.2),
          inset -2px -2px 4px rgba(53, 62, 110, 0.9),
          inset 2px 2px 5px rgba(13, 16, 28, 0.9);
      }

      svg {
        display: block;
        width: 25px;
        height: 25px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    .modal {
      &__title {
        @include gradient-text($purple_gradient);
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
      }
    }
  }

  &__body {
    height: 80%;
    overflow-y: auto;
    @include custom-scroll;

    & > * {
      > * {
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px;

    > *:not(:last-child) {
      margin-right: 50px;
    }
  }
}
