.modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.localization-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-footer {
  display: flex !important;
  justify-content: flex-end;
}

.additional-button {
  display: flex !important;
  gap: 5px;
  color: white !important;
  align-items: center;
}

.action-button {
  color: white !important;
}

.input {
  text-align: start;
}

.input-label {
  width: 100px;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
