@import "../../index.scss";

.langComponent {
  &.vertical {
    position: fixed;
    padding: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #212745;
    box-shadow: 19px 18px 11px -6px #191e35, -9px -7px 14px #293054,
      inset 8px 8px 10px -11px rgba(14, 21, 45, 0.49),
      inset -13px -18px 12px -7px rgba(20, 26, 54, 0.45);
    border-radius: 15px 0px 0px 15px;
  }

  &.notification {
    padding: 20px;
    border-radius: 20px 0px 0px 20px;
    box-shadow: 3px 3px 8px 0px rgba(13, 16, 28, 0.9),
      -3px -3px 6px 0px rgba(53, 62, 110, 0.9),
      3px -3px 6px 0px rgba(13, 16, 28, 0.2),
      -3px 3px 6px 0px rgba(13, 16, 28, 0.2),
      -1px -1px 2px 0px rgba(13, 16, 28, 0.5) inset,
      1px 1px 2px 0px rgba(53, 62, 110, 0.3) inset;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    &-item {
      list-style: none;
      height: 50px;
      width: 50px;
      &:hover {
        cursor: pointer;
      }
      &.active {
        border: 1px solid #3ddde8;
        filter: drop-shadow(0px 0px 4px #3ddde8)
          drop-shadow(0px 0px 4px #3ddde8);
        border-radius: 50px;
      }
    }

    &.vertical,
    &.notification {
      flex-direction: column;

      .langComponent__list-item {
        margin-bottom: 20px;
        height: 50px;
        width: 50px;
        // &.active {
        //   border: 1px solid #3ddde8;
        //   filter: drop-shadow(0px 0px 4px #3ddde8)
        //     drop-shadow(0px 0px 4px #3ddde8);
        //   border-radius: 50px;
        // }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .langComponent {
    &.vertical {
      top: calc(50% + 50px);
      .langComponent {
        &__list {
          &-item {
            height: 30px;
            width: 30px;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    &__list {
      &-item {
        height: 40px;
        width: 40px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
