@import "../../index.scss";
@import "../../mixins.scss";

.images {
  height: 100vh;
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;

  @include custom-scroll;
}

@media screen and (max-width: $media-tablet) {
  .images {
  }
}
