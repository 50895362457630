@import "../../index.scss";

.modal {
  max-width: 506px;
  width: 100%;
  box-sizing: border-box;
  &.date {
    height: 570px;
    .modal {
      &__title {
        text-align: center;
      }
    }
    .react-datepicker.datapicker-component {
      display: block;
    }
  }
  &__title {
    font-size: 20px;
    color: $white;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 700;
  }
  &__form {
    position: relative;
  }

  &__text {
    text-align: center;
    color: $white;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;

    span {
      width: 100%;
      display: block;
    }
  }

  &__success-text {
    color: $green_02;
  }

  &__error-text {
    color: $red;
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin: 30px 0 20px;

    > *:not(:last-child) {
      margin-right: 30px;
    }
  }
}
.datePickerWrap {
  height: 400px;
  padding-top: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;

  > * {
    width: 300px;
    margin: 0 auto;

    :global {
      .react-datepicker-popper {
        width: 100% !important;
      }

      .react-datepicker__input-container {
        input {
          width: 100%;
        }
      }
    }
  }
}
