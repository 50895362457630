@import "../../index.scss";

.text-area {
  display: flex;

  &.placeholder-white {
    ::-webkit-input-placeholder {
      color: $white;
    }
    ::-moz-placeholder {
      color: $white;
      opacity: 1;
    }
    :-moz-placeholder {
      color: $white;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: $white;
    }
    ::-ms-input-placeholder {
      color: $white;
    }
    ::placeholder {
      color: $white;
      opacity: 1;
    }
  }

  &.is-empty {
    textarea {
      border-color: $grey_03;
    }
  }

  &.align-left {
    textarea {
      text-align: left;
    }
  }
}

.sm {
  textarea {
    height: 28px !important;
    min-height: 28px !important;
    margin: 5px 0 !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    padding: 2px 0;
  }
}

.sm-2 {
  textarea {
    height: 39px !important;
    min-height: 39px !important;
    width: 80px !important;
    margin: 0 !important;
    padding: 7px 0 !important;
    color: $white;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    &::placeholder {
      color: $blue_text;
    }

    &:focus {
      border-color: #3ddde8;
    }
  }
}

textarea {
  border: 1px solid #3ddde8;
  border-radius: 52px;
  overflow: hidden;
  background-color: $main_bg_color;
  width: calc(100%);
  text-align: center;
  color: $white;
  margin: 10px 0;
  height: 50px;
  min-height: 50px !important;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  padding: 12px 15px 8px;
  resize: none;
  box-sizing: border-box;
  font-family: Inter;

  &:focus {
    outline: 2px;
  }
}
