@import "../../../mixins.scss";

.modal-event {
  &__list {
    max-height: 410px;
  }

  &__list-inner {
    max-height: 205px;
    overflow-y: scroll;

    @include custom-scroll;

    input {
      text-align: left;
      padding-right: 35px;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    position: relative;

    .edit-btn {
      position: absolute;
      right: 10px;
      bottom: 15px;
      width: 18px;
      height: 18px;
    }
  }
}
