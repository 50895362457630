@import "../../../index.scss";

.sort {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &__btn {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  .sublist {
    position: absolute;
    top: calc(100%);
    padding: 5px;
    margin-top: 5px;
    min-width: 40px;
    background: rgba(43, 129, 149, 0.7);
    backdrop-filter: blur(10px);
    z-index: 1;
    border-radius: 10px;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: $white;
      display: block;
      padding: 5px 15px 11px;
      text-align: center;
      cursor: pointer;

      &.default {
        padding: 5px 0px 11px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #3ddde8;
      }

      &:hover {
        color: #3ddde8;

        .asc,
        .desc {
          color: #fff;

          svg {
            fill: #fff;

            path {
              color: #fff;
              stroke: #fff;
            }
          }
        }
      }

      &.selected {
        color: #3ddde8;

        .asc,
        .desc {
          color: #fff;

          svg {
            fill: #fff;

            path {
              color: #fff;
              stroke: #fff;
            }
          }
        }
      }
    }
  }

  .asc,
  .desc {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3ddde8;

    svg {
      display: block;
      margin-left: 5px;
    }
  }

  .desc {
  }
}
