@import "../../index.scss";
@import "../../mixins.scss";

.notification {
  height: 100vh;
  width: 100%;
  padding: 40px 60px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;

  @include custom-scroll;

  &__inner {
    padding-right: 59px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include gradient-text($purple_gradient);
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }

  &__header {
    margin-bottom: 20px;

    > *:nth-child(2) {
      margin-top: 20px;
    }
  }

  &__body {
    margin-bottom: 30px;
  }

  &__pagination {
    margin-top: auto;
  }
}

@media screen and (max-width: $media-tablet) {
  .notification {
  }
}
