.header {
  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    padding-right: 20px;
  }

  &-tools {
    position: absolute;
    right: 40px;

    &__edit {
      cursor: pointer;
    }
  }

  &-slider {
    display: flex;
    align-items: center;
    max-width: calc(100% - 125px);
    padding-left: 58px;
    margin-right: 10px;
    position: relative;
    overflow-x: auto;

    .swiper-wrapper {
      height: 68px;
    }
  }

  &-events {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-right: 55px;

    &__prev,
    &__next {
      position: absolute;
      cursor: pointer;
      z-index: 10;
    }

    &__prev {
      left: 20px;
    }

    &__next {
      right: 0;
      transform: rotate(180deg);
    }

    .swiper-button-lock,
    .swiper-button-disabled {
      display: block;
      opacity: 0.5 !important;
      pointer-events: none;
    }

    &__add {
      cursor: pointer;
      margin-left: 10px;
    }

    // padding-top: 110px;
    &__buttons {
      display: flex;
    }
  }
}
