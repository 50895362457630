@import "../../../index.scss";
@import "../../../mixins.scss";

.unpublished-event {
  position: relative;
  height: calc(100vh - 108px);
  overflow-y: auto;
  @include custom-scroll;

  .content {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
    // &__steps {
    //   display: flex;
    //   flex-wrap: nowrap;
    //   height: 100%;
    //   width: 100%;
    // }
    &__eventList {
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // gap: 30px 20px;
      // margin-bottom: 30px;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > * {
        width: calc((100% - 40px) / 3);
        margin-bottom: 20px;
        max-width: unset;

        &:not(:nth-child(3n)) {
          margin-right: 20px;
        }
      }
    }

    .pagination {
      margin-top: 30px;
    }

    &__tips {
      padding-top: 20px;
      padding-left: 37px;
    }

    &__pages {
      width: 100%;
    }

    &__empty-text {
      @include gradient-text($purple_gradient);
      width: 100%;
      margin-top: 200px;
      text-align: center;
      font-size: 44px;
    }
  }

  &__img {
    img {
      right: 80px;
      position: absolute;
      bottom: 20px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .unpublished-event {
    .content {
      &__eventList {
        > * {
          width: calc((100% - 20px) / 2);
          margin-right: 20px;

          &:nth-child(2n) {
            margin-right: 0px;
          }

          > div {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media-hd) {
  .unpublished-event {
    &__img {
      img {
        width: 300px;
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: $media-tablet) {
  .unpublished-event {
    &__img {
      img {
        width: 250px;
        height: 250px;
      }
    }
  }
}

@media screen and (max-width: $media-tablet-small) {
  .unpublished-event {
    &__img {
      display: none;
    }
  }
}
