@import "../../../index.scss";

.image-step {
  padding: 10px 40px 40px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

@media screen and (max-width: $media-hd) {
  .image-step {
    padding: 40px;
  }
}

@media screen and (max-width: $media-notebook) {
  .image-step {
    padding: 20px;
  }
}
