.wrapperTooltip {
  max-width: 100%;
  overflow-x: hidden;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.bgTooltip {
  border: 1px solid transparent;
  border-radius: 10px !important;
  padding: 8px 21px;
  background-blend-mode: overlay, normal !important;
  background: #233753 !important;

  @supports (backdrop-filter: blur(20px)) {
    backdrop-filter: blur(20px);
    background: linear-gradient(
      101.35deg,
      rgba(57, 198, 212, 0.12) 0.3%,
      rgba(57, 198, 212, 0) 109.42%
    ) !important;
  }
}

.tooltip {
  max-width: 300px;
  padding: 0 !important;
  background: #212745 !important;
  border: 1px solid #499dbe !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  &.full {
    max-width: 100%;
  }
  &:after {
    display: none;
  }
  &:before {
    display: none;
  }
}

.hiddenTrigger {
  width: 310px;
  display: inline;
  z-index: -1;
  left: 0;
  visibility: hidden;
  position: absolute;
}
