@import "../../../index.scss";
@import "../../../mixins.scss";

.steps-button {
  width: 215px;
  height: auto;
  padding: 15px;
  border-radius: 50px;
  color: $main_bg_color;
  background: $disabled_gradient;
  box-shadow: none;
  margin-top: 0;

  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;

  outline: none;

  span {
    position: relative;
    z-index: 12;
    font-family: Inter;
  }

  &:not(.visited):hover {
    span {
      @include gradient-text($disabled_gradient);
    }
  }

  &.selected,
  &:not(.visited):hover {
    background: transparent;
    background: $disabled_gradient;

    overflow: hidden;
    position: relative;
    z-index: 1;

    &::before {
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 4px;
      right: 4px;
      background: $main_bg_color;
      content: "";
      z-index: 0;
      border-radius: 50px;
    }

    &::after {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: $disabled_gradient;
      content: "";
      z-index: -1;
      border-radius: 50px;
    }
  }

  &.dirty.invalid,
  &.visited.invalid {
    background: $red_gradient;
  }

  &.valid.visited {
    background: $red_gradient;
  }

  &.dirty.valid.stored,
  &.visited.valid.stored {
    background: $green_gradient;
  }

  &.selected.invalid {
    span {
      @include gradient-text($red_gradient);
    }

    &::after {
      background: $red_gradient;
    }
  }

  &.selected.valid {
    span {
      @include gradient-text($green_gradient);
    }

    &::after {
      background: $green_gradient;
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .steps-button {
    width: 150px;
    font-size: 15px;
    line-height: 20px;
    padding: 10px;
  }
}
