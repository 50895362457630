@import "../../index.scss";

.settings-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__text {
    display: block;
    margin-bottom: 5px;
    font-family: "Noto Serif Tamil Slanted";
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: $blue_01;
  }
  button {
    outline: none;
    border: none;
    background: none;
  }

  &__icon {
    display: block;
    width: 34px;
    height: 34px;
    background: url("../../assets/controls/gear-2.svg") no-repeat;
  }

  &:hover,
  &:focus,
  &:active {
    .settings-button {
      &__icon {
        width: 34px;
        height: 34px;
        background: url("../../assets/controls/gear-2-hover.svg") no-repeat;
        background-size: contain;
      }
    }
  }
}
