@import "../../index.scss";

.modal-warning {
  max-width: 375px;
  width: 100%;

  &__stop {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 5px auto 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    text-align: center;
    color: $white;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    display: inline-block;
    span {
      width: 100%;
      display: inline;
      color: $green_02;
    }
  }

  .up-btn,
  .down-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    width: 100%;
    max-width: 138px;

    border-radius: 10px;
    padding: 10px;

    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $white;
    cursor: pointer;
  }

  .up-btn {
    background: #4db275;
    box-shadow: -6px -7px 14px -2px #246940, 4px 7px 8px 1px #0e2c1a,
      inset 6px 6px 10px #3c9861, inset -3px -3px 9px #143722;

    &:hover,
    &:active,
    &:focus {
      background: #4db275;
      box-shadow: -2px -2px 6px -1px #22603b, 4px 4px 5px -2px #235637,
        inset 4px 4px 5px #328151, inset -4px -4px 29px #4bbc78;
    }
  }

  .down-btn {
    background: #eb4755;
    box-shadow: -6px -7px 14px -2px #852830, 4px 7px 8px 1px #3d0f13,
      inset 6px 6px 10px #c83844, inset -3px -3px 9px #3a1013;

    &:hover,
    &:active,
    &:focus {
      background: #eb4755;
      box-shadow: -2px -2px 9px #872a32, 4px 4px 6px -3px #781d25,
        inset 4px 4px 5px -3px #781d25, inset -4px -4px 29px #cd4f59;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin: 30px 0 20px;

    > *:not(:last-child) {
      margin-right: 50px;
    }
  }
}
