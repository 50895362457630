@import "../../index.scss";

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  :global {
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $main_bg_color;
      box-shadow: $control_shadow;
      border-radius: 8px;
      min-width: 49px;
      height: 49px;
      margin: 0 6px;
      cursor: pointer;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px;
        color: $blue_text !important;
        font-size: 25px;
        line-height: 30px;
      }
      &.selected,
      &:not(.previous):not(.break):not(.next):hover,
      &:not(.previous):not(.break):not(.next):focus {
        box-shadow: $control_active_shadow;
      }
    }
    .previous,
    .break,
    .next {
      background: transparent;
      box-shadow: none;
      border: none;
      padding: 0;
      margin: 0 6px;
      min-width: 39px;
      height: 50px;

      a {
        padding: 0;
      }
    }
  }
}

.pagination-bnt {
  width: 100%;
  height: 100%;
  height: 50px;
  width: 70px;
  cursor: pointer;

  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #212745;
  box-shadow: $control_shadow;
  border-radius: 8px;
  min-width: 49px;
  height: 49px;
  margin: 0 6px;
  border-radius: 20px 0px 0px 20px;

  i {
    display: block;
    width: 20px;
    height: 17px;

    background: url("../../assets/controls/double_arrow_left.svg") no-repeat;
  }

  &.next {
    border-radius: 0px 20px 20px 0px;

    i {
      transform: rotate(180deg);
      background: url("../../assets/controls/double_arrow_left.svg") no-repeat;
    }
  }

  @media (hover: hover) {
    & {
      &:focus,
      &:hover {
        box-shadow: $control_active_shadow;
      }
    }
  }
}

@media screen and (max-width: $media-tablet-small) {
  .pagination-wrapper {
    justify-content: center;
    padding: 0;

    &.noWrap-mob {
      flex-wrap: nowrap;
    }

    :global {
      li {
        min-width: 39px;
        height: 39px;
        margin: 0 5px 10px;

        a {
          font-size: 15px;
          line-height: 18px;
        }
      }

      .previous,
      .break,
      .next {
        display: inline-block;
        margin: 0 5px 10px;
        a {
          padding: 0;
        }
        svg {
          width: 7px;
          height: 14px;
        }
      }

      .break {
        align-items: flex-end;
        display: flex;
      }
    }
  }
}
