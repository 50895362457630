@import "../../index.scss";

.modal-cancel {
  max-width: 687px;
  width: 80%;
  box-sizing: border-box;

  &__text {
    text-align: center;
    color: $white;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;

    span {
      width: 100%;
      display: block;
    }
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    margin-top: 10px;

    > *:nth-child(1) {
      width: 100%;
      max-width: 370px;
    }

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  input {
    margin: 0px;
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin: 30px 0 20px;

    > *:not(:last-child) {
      margin-right: 50px;
    }
  }
}
