@import "../../index.scss";

.status {
  width: 34px;
  height: 30px;
  outline: none;
  border: none;
  background: none;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    width: 100%;
    display: block;
  }
}
