@import "../../index.scss";
@import "../../mixins.scss";

.date-dropdown {
  * {
    box-sizing: border-box;
  }

  .label-wrap {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    .label {
      @include gradient-text($purple_gradient);
      @include custom-scroll;
      display: inline-block;
      max-width: 200px;
      text-align: center;
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 31px;
      font-family: "Noto Serif Tamil Slanted";
    }
  }

  font-weight: 400;
  font-size: 25px;
  line-height: 30px;

  &__wrap {
    position: relative;
  }

  &__value {
    height: 54px;
    padding: 12px;
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    svg {
      width: 20px;
      height: 11px;
    }
  }

  &__calendar {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 10px;
    height: 430px;
    padding: 356px 20px 20px;
    z-index: 1;
    background: #202643;
    box-shadow: -3px 3px 6px rgba(13, 16, 28, 0.2),
      3px -3px 6px rgba(13, 16, 28, 0.2), -3px -3px 6px rgba(53, 62, 110, 0.9),
      3px 3px 8px rgba(13, 16, 28, 0.9),
      inset 1px 1px 2px rgba(53, 62, 110, 0.3),
      inset -1px -1px 2px rgba(13, 16, 28, 0.5);
    border-radius: 10px;
  }

  &.open {
    .date-dropdown {
      &__wrap {
        box-shadow: inset -5px 5px 10px rgba(18, 21, 37, 0.2),
          inset 5px -5px 10px rgba(18, 21, 37, 0.2),
          inset -5px -5px 10px rgba(48, 57, 101, 0.9),
          inset 5px 5px 13px rgba(18, 21, 37, 0.9);
      }
    }
  }

  &__wrap {
    background: linear-gradient(135deg, #232948 0%, #1f2542 100%);
    box-shadow: -3px 3px 6px rgba(13, 16, 28, 0.2),
      3px -3px 6px rgba(13, 16, 28, 0.2), -3px -3px 6px rgba(53, 62, 110, 0.9),
      3px 3px 8px rgba(13, 16, 28, 0.9),
      inset 1px 1px 2px rgba(53, 62, 110, 0.3),
      inset -1px -1px 2px rgba(13, 16, 28, 0.5);
    border-radius: 10px;

    &:hover {
      box-shadow: inset -5px 5px 10px rgba(18, 21, 37, 0.2),
        inset 5px -5px 10px rgba(18, 21, 37, 0.2),
        inset -5px -5px 10px rgba(48, 57, 101, 0.9),
        inset 5px 5px 13px rgba(18, 21, 37, 0.9);
    }

    span {
      font-weight: 400;
      font-size: 25px;
      line-height: 30px;
      color: $white;
    }
  }
}
