@import "../../../index.scss";
@import "../../../mixins.scss";

.notification {
  height: 100vh;
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;

  @include custom-scroll;

  &__title {
    @include gradient-text($purple_gradient);
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: $media-tablet) {
  .notification {
  }
}
