@import "../../index.scss";

.modal {
  max-width: 438px;
  width: 80%;
  color: $white;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  box-sizing: border-box;
  padding: 20px 27px !important;

  &__btn {
    display: flex;
    justify-content: flex-end;
  }

  &__info {
    margin-top: 10px;

    &-row {
      padding: 6px 0;
      border-bottom: 1px solid #3ddde8;
      display: flex;
      flex-wrap: nowrap;

      span {
        display: block;
      }

      > *:nth-child(1) {
        width: 100%;
        max-width: 130px;
        padding-right: 7px;
      }

      > *:nth-child(2) {
        width: 100%;
        max-width: 247px;
      }
    }
  }

  &__text {
    display: block;
    margin-top: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}
