@import "../../../index.scss";

.time-dropdown {
  position: absolute;
  bottom: -200px;
  z-index: 10;
  left: calc(0);
  width: 100%;
  max-height: 200px;
  background: #212745;
  overflow: hidden;
  display: none;

  &.opened {
    display: block;
  }

  &__inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    max-height: 200px;
    padding: 10px;
    width: 100%;

    background: linear-gradient(
      101.35deg,
      rgba(57, 198, 212, 0.12) 0.3%,
      rgba(57, 198, 212, 0) 109.42%
    );

    > div {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  &__item {
    cursor: pointer;
    margin: 5px 0;
    padding: 3px;
    border-radius: 8px;
    &.selected {
      background: #229dbb;
    }
  }
  border: 1px solid #3ddde8;
  border-radius: 10px;
}
