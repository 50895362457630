@import "../../index.scss";

.input {
  display: flex;

  &.placeholder-white {
    ::-webkit-input-placeholder {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      font-family: monospace;
    }
    :-moz-placeholder {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      font-family: monospace;
    }
    ::-moz-placeholder {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      font-family: monospace;
    }
    :-ms-input-placeholder {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      font-family: monospace;
    }
    ::-ms-input-placeholder {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      font-family: monospace;
    }
    ::placeholder {
      color: $white;
      font-size: 20px;
      font-weight: 700;
      font-family: monospace;
    }
  }

  &.is-empty {
    input {
      border-color: $grey_03;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.date-input {
    position: relative;

    * {
      box-sizing: border-box;
      font-family: "Inter";
    }

    input {
      box-sizing: border-box;
      background: linear-gradient(
        90.62deg,
        rgba(57, 198, 212, 0.17) 0%,
        rgba(61, 221, 232, 0.11) 100%
      );
    }

    :global {
      .react-datepicker-popper {
        width: 100%;
        position: relative;
      }

      .react-datepicker__navigation {
        top: 10px;
      }

      .react-datepicker__header {
        background: transparent;
        border-bottom: none;
        padding: 10px 15px;

        .react-datepicker__current-month {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $white;
          margin-top: 4px;
        }
      }

      .react-datepicker__navigation {
        background-color: $main_bg_color;
        box-shadow: 9px 5px 8px 4px #191e35, -3px -3px 14px 2px #2c345c,
          inset -4px -4px 5px -1px rgba(5, 7, 13, 0.49),
          inset 4px 4px 3px -19px rgba(62, 72, 118, 0.45);
        border-radius: 10px;
        // width: 100%;
        color: #3ddde8;
        padding: 0;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        height: 32px;
        width: 32px;
        border: none;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
        }

        &:focus-visible {
          box-shadow: $inner_shadow;
        }
        &:focus {
          outline: 2px;
        }
        &:active {
          outline: 2px;
          box-shadow: $inner_shadow;
        }

        > span {
          &:after,
          &::before {
            border-color: #ffd01f;
            top: 9px;
          }
        }

        &.react-datepicker__navigation--previous {
          left: 15px;
        }

        &.react-datepicker__navigation--next {
          right: 15px;
        }
      }

      .react-datepicker.datapicker-component {
        width: 100%;
        border: 1px solid $blue_text;
        border-radius: 10px;
        background: linear-gradient(
          101.35deg,
          rgba(57, 198, 212, 0.12) 0.3%,
          rgba(57, 198, 212, 0) 109.42%
        );

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $white;
      }

      .react-datepicker__month-container {
        width: 100%;

        .react-datepicker__month {
          .react-datepicker__day {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: $white;
            margin: 0px 6px;
            padding: 6px;

            &:hover {
              background: $blue_02;
            }
          }

          .react-datepicker__day--selected {
            border-radius: 8px;
            background: $blue_02;
          }

          .react-datepicker__day--disabled {
            cursor: auto;
            opacity: 0.3;
          }
        }
      }

      .react-datepicker__day-names {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        > div {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: $grey_01;
          text-transform: uppercase;
        }
      }

      .react-datepicker__day-name {
        margin-left: 6px;
        margin-right: 6px;
      }

      .react-datepicker__month {
        margin-right: 0;
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;
      }

      .react-datepicker__week {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      .react-datepicker__input-time-container {
        display: flex;
        align-items: center;
        justify-content: center;
        float: unset;
        width: 100%;
        padding: 0 23px 15px;
        margin: 0;

        font-weight: 400;
        font-size: 25px;
        line-height: 29px;
      }

      .react-datepicker__input-container {
        input {
          font-size: 18px;
          font-weight: 400;
          line-height: 21.09px;
        }
      }
    }
  }
}

.sm {
  input {
    height: 28px;
    font-size: 15px;
    font-weight: 700;
  }
}

input {
  border: 1px solid #3ddde8;
  border-radius: 52px;
  overflow: hidden;
  background-color: $main_bg_color;
  width: calc(100% - 5px);
  text-align: center;
  color: $white;
  margin: 10px 0;
  height: 44px;
  font-size: 20px;
  font-weight: 700;
  padding: 0px 15px;

  &:focus-visible {
  }
  &:focus {
    outline: 2px;
  }
}

.popper {
  position: static !important;
  transform: none !important;
}

.userHours {
  .input {
    flex-direction: column !important;
  }
}

.input {
  flex-direction: column !important;
}
