.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 50;

  &-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    align-items: center;

    > div:first-child {
      background: #212745;
      box-shadow: 19px 18px 11px -6px #191e35, -9px -7px 14px #293054,
        inset 8px 8px 10px -11px rgba(14, 21, 45, 0.49),
        inset -13px -18px 12px -7px rgba(20, 26, 54, 0.45);
      border-radius: 15px;
      padding: 20px;
    }

    &.background {
      background: rgba(91, 96, 105, 0.4);
    }
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
  }
}
