@import "../../index.scss";

.events {
  position: relative;
  height: calc(100vh - 108px);

  &__tips {
    padding-top: 40px;
    padding-left: 57px;
  }

  .icon2 {
    right: 80px;
    position: absolute;
    bottom: 0px;
    width: 532px;
    height: 532px;
  }
}

@media screen and (max-width: $media-hd) {
  .events {
    .icon2 {
      width: 300px;
      height: 300px;
    }
  }
}

@media screen and (max-width: $media-tablet) {
  .events {
    .icon2 {
      width: 250px;
      height: 250px;
    }
  }
}

@media screen and (max-width: $media-tablet-small) {
  .events {
    .icon2 {
      display: none;
    }
  }
}
