@import "../../index.scss";
@import "../../mixins.scss";

.settings {
  padding: 30px 40px;
  height: 100vh;
  overflow-y: scroll;
  @include custom-scroll;

  &__header {
    margin-bottom: 40px;
  }

  &__title {
    margin: 0;
    @include gradient-text($purple_gradient);
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
  }
}

.toggles-container {
  display: flex;
  column-gap: 50px;
}
