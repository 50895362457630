@import "../../index.scss";

.image-list {
  height: 100%;
  box-sizing: border-box;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    > *:nth-child(1) {
      width: 100%;
      max-width: 415px;
      margin-right: 20px;
      position: relative;
      top: -10px;
    }

    > *:nth-child(2) {
      margin-right: 20px;
      margin-left: auto;
    }

    > *:nth-child(3) {
      margin-left: 20px;
    }

    > *:last-child {
      margin-right: 0px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    min-height: calc(100% - 75px);

    &__list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      &-item {
        height: 100%;
        width: calc((100% - 60px) / 3);
        margin-bottom: 30px;

        &:not(:nth-child(3n)) {
          margin-right: 30px;
        }
      }
    }

    &__pagination {
      margin-left: auto;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .image-list {
    &__body {
      &__list {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: $media-hd) {
  .image-list {
    &__body {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__list {
        &-item {
          height: 100%;
          width: calc((100% - 60px) / 2);
          margin-bottom: 30px;

          margin-right: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .image-list {
  }
}
