@import "../../index.scss";

.modal-transfer {
  max-width: 457px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 82px;

  &__header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__arrow-wrapper {
    margin-right: auto;
    position: absolute;
    left: -32px;
    top: 0;
  }

  &__title {
    width: 332px;
    margin: 0;
    text-align: center !important;
    font-size: 25px !important;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;

    input {
      margin: 0;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    span {
      color: #ff3131;
      font-size: 12px;
      font-weight: 700;
      line-height: 150%;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
  }
}
