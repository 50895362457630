@import "../../index.scss";

.input {
  display: flex;
  flex-direction: column;

  & {
    ::-webkit-input-placeholder {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      font-family: Inter;
      text-align: center;
    }
    ::-moz-placeholder {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      font-family: Inter;
      text-align: center;
    }
    :-moz-placeholder {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      font-family: Inter;
      text-align: center;
    }
    :-ms-input-placeholder {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      font-family: Inter;
      text-align: center;
    }
    ::-ms-input-placeholder {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      font-family: Inter;
      text-align: center;
    }
    ::placeholder {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      font-family: Inter;
      text-align: center;
    }
  }

  &.placeholder-white {
    ::-webkit-input-placeholder {
      color: $white;
    }
    ::-moz-placeholder {
      color: $white;
      opacity: 1;
    }
    :-moz-placeholder {
      color: $white;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: $white;
    }
    ::-ms-input-placeholder {
      color: $white;
    }
    ::placeholder {
      color: $white;
      opacity: 1;
    }
  }

  &.is-empty {
    input {
      border-color: $grey_03;
    }
  }

  &.bordered {
    input {
      border: 1px solid #3ddde8 !important;
    }
  }

  &.centered {
    input {
      text-align: center;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  label {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $white;
  }

  .form-label {
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
  }

  input {
    border: 1px solid #3ddde8;
    border-radius: 52px;
    overflow: hidden;
    background-color: $main_bg_color;
    text-align: left;
    color: $white;
    margin: 10px 0;
    height: 50px;
    font-size: 25px;
    font-weight: 700;
    padding: 0px 15px;
    width: calc(100%);
    box-sizing: border-box;
    padding: 3px 20px;
    font-family: Inter;

    &:focus-visible {
    }
    &:focus {
      outline: 2px;
    }
  }

  .inForm {
    margin: 0;
    height: 28px;
    width: 350px;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
  }

  &.search {
    position: relative;

    input {
      border: none;
      border-radius: 50px;
      padding-right: 55px;

      &.is-empty {
        background: rgba(61, 221, 232, 0.17);
      }

      &,
      &:focus,
      &:active,
      &:hover {
        background: #212745;
        box-shadow: $control_active_shadow;
      }
    }

    i {
      display: block;
      position: absolute;
      right: 27px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;

      background: url("../../assets/controls/search.svg") no-repeat;
    }
  }

  &.balance {
    position: relative;

    &_wrap {
      margin: 0;
      overflow: hidden;
    }
    input {
      box-sizing: border-box;
      border: none;
      border-radius: 50px;
      padding-right: 55px;
      height: 48px;
      border: 1px solid #3ddde8;
      border-radius: 10px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      padding: 15px 55px 15px 15px;
      color: #3ddde8;
      margin: 0;

      &,
      &:focus,
      &:active,
      &:hover {
        background: transparent;
      }
    }
    transition: all 1s ease;

    span {
      display: block;
      position: absolute;
      box-sizing: border-box;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding-left: 40px;
      background: #264661;
      color: #55e83d;
      border-radius: 8px;
      border: 1px solid #3ddde8;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.8s ease;
    }

    .complete {
      display: block;
      position: absolute;
      z-index: 2;
      right: 0px;
      left: calc(100% - 40px);
      top: 0;
      width: 40px;
      height: 48px;
      background: #264661;
      border: 1px solid #3ddde8;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 15px;
      line-height: 18px;

      &:hover {
        background: transparent;
      }
      transition: all 0.8s ease;

      &.completed {
        left: 0;
        right: unset;
      }
    }
  }

  &.transferBig {
    width: 351px;
  }

  &.transferSmall {
    width: 185px;
  }
}

.form-input {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  row-gap: 26px;
}

.sm {
  & {
    ::-webkit-input-placeholder {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
    }
    ::-moz-placeholder {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
    }
    :-moz-placeholder {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
    }
    :-ms-input-placeholder {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
    }
    ::-ms-input-placeholder {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
    }
    ::placeholder {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
    }
  }
  input {
    height: 28px;
    font-size: 15px;
    font-weight: 700;
  }
}

.sm-2 {
  input {
    height: 35px;
    font-size: 15px;
    font-weight: 700;
  }
}
