@import "../../index.scss";

.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 155px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  box-shadow: -4px -4px 11px #191e35, 4px 4px 14px #293054,
    inset -4px -4px 5px rgba(5, 7, 13, 0.49),
    inset 4px 4px 29px rgba(62, 72, 118, 0.45);
  border-radius: 10px;

  cursor: pointer;

  overflow: hidden;

  &__icon {
    display: block;
    width: 45px;
    height: 50px;

    background: url("../../assets/controls/download.svg") no-repeat;
    background-size: contain;
  }

  &__preview {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.9;

    .arrow-button {
      &__icon {
        background: url("../../assets/controls/download.svg") no-repeat;
      }
    }
  }

  &.selected {
    border: 1px solid #3ddde8;
  }

  &.invalid-type {
    border: 1px solid $red;
  }

  input {
    position: absolute;
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    opacity: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
}
