@import "../../index.scss";

.modal-time {
  max-width: 461px;
  width: 80%;
  padding: 20px !important;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: -10px -10px 25px 0px rgba(13, 16, 28, 0.9) inset,
    10px 10px 20px 0px rgba(53, 62, 110, 0.9) inset,
    -10px 10px 20px 0px rgba(13, 16, 28, 0.2) inset,
    10px -10px 20px 0px rgba(13, 16, 28, 0.2) inset,
    1px 1px 2px 0px rgba(13, 16, 28, 0.5),
    -1px -1px 2px 0px rgba(53, 62, 110, 0.3);

  &__title {
    @include gradient-text($purple_gradient);
    font-weight: 700;
    font-size: 25px;
    line-height: normal;

    margin-bottom: 20px;
  }

  &__calendar-wrapper {
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    column-gap: 40px;
  }
}
