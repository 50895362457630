@import "../../index.scss";
@import "../../mixins.scss";

.form {
  &__body {
    max-width: 978px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    &-title {
      margin: 0;
      display: flex;
      justify-content: center;
      position: absolute;
      top: -55px;
      width: 100%;

      span {
        @include gradient-text($purple_gradient);
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        display: inline;
      }
    }

    &-row {
      display: grid;
      grid-template-columns: 1.5fr 0.2fr 0.2fr;
      align-items: center;
      column-gap: 10px;
      position: relative;

      &:first-child {
        margin-top: 0px;
        > div:nth-child(1) {
          position: relative;
        }
      }
    }
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;

    > div {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1920px) {
  .form-container {
    .form {
      &__header {
        &-inner {
          > *:nth-child(1) {
            margin-right: 40px;
          }

          > *:nth-child(2) {
            margin-right: auto;
          }

          > *:nth-child(3) {
            margin-right: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .form-container {
    .form {
      &__header {
        &-inner {
          flex-direction: column;
          justify-content: center;

          > * {
            margin-right: 0px !important;

            &:not(:last-child) {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }
}
