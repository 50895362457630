// todo: not use
// @import "bootstrap/dist/css/bootstrap.min.css";
@import "assets/fonts/fonts.scss";
@import "./mixins.scss";

$black: #373737;
$main_bg_color: #212745;
$white: #ffffff;
$blue_text: #3ddde8;
$green: #4db275;
$green_01: #60e394;
$green_02: #01ff66;
$green_03: #53d888;
$blue: #2d9cdb;
$blue_01: #499dbe;
$blue_02: #229dbb;
$blue_03: #499dbe;
$blue_04: #015bbb;
$blue_05: #3ddde830;
$red: #fa2b2b;
$red_2: #ff0000;
$yellow: #ffd01f;
$yellow_01: #fed500;
$green_01: #4db375;
$grey_01: #667998;
$grey_02: #5d6e8d;
$grey_03: #5b6069;
$violet: #6474bf;

$menu_line_shadow: 2px 3px #191e35, -2px -2px 5px #2c345c,
  inset -2px -2px 2px rgba(5, 7, 13, 0.49),
  inset 2px 2px 2px rgba(62, 72, 118, 0.45);
$inner_shadow: -5px -8px 9px 5px #293054, 8px 9px 9px 6px #191e35,
  inset -4px -4px 5px #313b68, inset 4px 4px 8px #05070d;
$control_shadow: 4px 7px 8px 1px #191e35, -3px -4px 9px #2c345c,
  inset -4px -4px 5px -1px rgb(5 7 13 / 49%),
  inset 4px 4px 29px -19px rgb(62 72 118 / 45%);
$control_active_shadow: 1px 1px 2px rgba(53, 62, 110, 0.3),
  -1px -1px 2px rgba(13, 16, 28, 0.5), inset -3px 3px 6px rgba(13, 16, 28, 0.2),
  inset 3px -3px 6px rgba(13, 16, 28, 0.2),
  inset -3px -3px 6px rgba(53, 62, 110, 0.9),
  inset 3px 3px 8px rgba(13, 16, 28, 0.9);
$cards_shadow: 19px 18px 11px -6px #191e35, -9px -7px 14px #293054,
  inset 8px 8px 10px -11px rgba(14, 21, 45, 0.49),
  inset -13px -18px 12px -7px rgba(20, 26, 54, 0.45);

$loader_gradient: rgba(184, 107, 248, 0.87);
$red_gradient: linear-gradient(93.33deg, #f05151 0%, #702626 100%);
$green_gradient: linear-gradient(93.33deg, #85f067 0%, #3e7030 100%);
$disabled_gradient: linear-gradient(91.98deg, #4d505a -25.63%, #868f96 106.52%);
$blue_gradient: linear-gradient(
  90.62deg,
  rgba(57, 198, 212, 0.17) 0%,
  rgba(61, 221, 232, 0.11) 100%
);
$border_gradient: linear-gradient(
  90.62deg,
  rgba(26, 31, 56, 1) 0%,
  rgba(43, 51, 90, 1) 100%
);
$purple_gradient: linear-gradient(
  97.17deg,
  #6bb5f8 0%,
  rgba(184, 107, 248, 0.87) 101.96%
);

// media query
$notebook: (
  max-width: 1279px,
);
$tablet: (
  max-width: 1024px,
);
$tablet-small: (
  max-width: 991px,
);
$mobile: (
  max-width: 767px,
);
$mobile-small: (
  max-width: 576px,
);
$media-hd: 1366px;
$media-xl: 1280px;
$media-notebook: 1279px;
$media-tablet: 1024px;
$media-tablet-small: 991px;
$media-mobile: 767px;
$media-mobile-small: 576px;

html {
  background-color: $main_bg_color;
  font-family: Inter, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  margin: 0;
  @include custom-scroll;
  background-color: #212745;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  button {
    border: none;
    cursor: pointer;
  }
}

.label {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  display: contents;
  color: #ffffff;
}

.cleared-button {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: transparent;
  padding: 0;
  margin-top: 0;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

.custom-select {
  width: 100%;
  max-width: 638px;
  background: rgba(34, 52, 79, 0.65);
  border: 1px solid $blue_text;
  border-radius: 10px;
  z-index: 10;

  &.select-menu-open {
    border-radius: 10px 10px 0 0;
    border-bottom: none;

    [class*="-indicatorContainer"] {
      transform: rotate(180deg);
    }
  }

  [class*="-control"] {
    background: transparent;
    border: none;
    box-shadow: none;

    &:hover {
      border: none;
      box-shadow: none;
    }

    > div {
      padding-top: 0px;
      padding-bottom: 0px;

      > div {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    input {
      height: 38px;
    }
  }

  [class*="-singleValue"] {
    color: $white;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }

  [class*="-indicatorContainer"] {
    svg {
      fill: $blue_text;
    }
  }

  [class*="-menu"] {
    z-index: 11;
    margin: 0px 0px 0px -1px;
    padding: 0;
    background: rgba(34, 52, 79, 0.65);
    color: $white;
    border-radius: 0 0 10px 10px;
    border: 1px solid $blue_text;
    border-top: none;
    box-shadow: none;
    box-sizing: content-box;
    overflow: hidden;
    width: 100%;

    > div {
      scrollbar-color: $blue_text #265b62 !important;

      &::-webkit-scrollbar {
        background: #265b62;
        border-radius: 10px;
        width: 4px;
        margin-left: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 3px solid $blue_text;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    [class*="-option"] {
      &:hover {
        background: #229dbb;
      }

      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.03em;
    }
  }
}

.link {
  color: $blue_text;
}

.notification-container {
  @include custom-scroll;

  padding: 0 !important;
  top: 70px !important;
  right: 20px !important;
  width: 500px !important;
  box-sizing: border-box;

  .notification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background: linear-gradient(135deg, #232948 0%, #1f2542 100%);
    box-shadow: -3px 3px 6px rgba(13, 16, 28, 0.2),
      3px -3px 6px rgba(13, 16, 28, 0.2), -3px -3px 6px rgba(53, 62, 110, 0.9),
      3px 3px 8px rgba(13, 16, 28, 0.9),
      inset 1px 1px 2px rgba(53, 62, 110, 0.3),
      inset -1px -1px 2px rgba(13, 16, 28, 0.5);
    border-radius: 10px;
    padding: 20px 20px 20px 90px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    font-family: Inter;
    min-height: 90px;

    &:after {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      height: 100%;
      width: 10px;
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      font-family: Inter;
      line-height: 24.2px;
    }
    .message {
      font-weight: 400;
      font-size: 15px;
      font-family: Inter;
      line-height: 18.5px;
    }
  }

  .notification-error {
    padding-top: 20px;
    padding-bottom: 20px;
    &:before {
      width: 50px;
      height: 50px;
      left: 20px;
      top: calc(50% - 25px);
      margin-top: 0;
      box-sizing: border-box;
      content: "";
      background: url(./assets/controls/close.svg) no-repeat;
      background-size: contain;
    }

    &:after {
      background: $red_2;
    }

    .notification-message {
      color: $red_2;
    }
  }

  .notification-success {
    padding-top: 20px;
    padding-bottom: 20px;
    &:before {
      width: 50px;
      height: 50px;
      left: 20px;
      top: calc(50% - 25px);
      margin-top: 0;
      box-sizing: border-box;
      content: "";
      background: url(./assets/controls/success.svg) no-repeat;
      background-size: contain;
    }

    &:after {
      background: $green_03;
    }

    .notification-message {
      color: $green_03;
    }
  }
}
