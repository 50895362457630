@import "index.scss";
.timeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 203px;
  color: $blue_text;
  background: #212745;
  border: 3px solid #1d223f;
  box-shadow: -4px -4px 11px #191e35, 4px 4px 14px #293054,
    inset -4px -4px 5px #313b68, inset 4px 4px 8px #05070d;
  border-radius: 10px;
  padding: 10px;
  &__title {
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  &__time,
  .time-value {
    padding: 0;
    display: flex;
    align-items: end;
    width: 100%;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    span {
      padding-left: 0;
      font-size: 12px;
    }
    .time-value__timezone {
      font-size: 10px;
      line-height: 12.1px;
      position: relative;
      left: 4px;
      top: -5px;
    }
  }
  .small {
    font-size: 21px;
  }
  &__progressLine {
    width: 100%;
    background: #2e354a;
    border-radius: 10px;
    height: 10px;
    overflow: hidden;
    .progress-line {
      background: linear-gradient(269.91deg, #33b366 0%, #6af2a0 100%);
      border-radius: 10px 0px 0px 10px;
      height: 10px;
    }
  }
  &.pageType {
    box-shadow: none;
    border: none;
    background: transparent;
    .timeContainer__title {
      font-size: 25px;
      line-height: normal;
    }
    .timeContainer__time,
    .time-value {
      font-size: 64px;
      line-height: normal;

      .time-value__timezone {
        font-size: 30px;
        line-height: 36.31px;
      }
    }
    .timeContainer__progressLine {
      height: 18px;
      .progress-line {
        height: 18px;
      }
    }
    .timeContainer__progressLine {
      max-width: 304px;
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .timeContainer {
    .timeContainer__time,
    .time-value {
      font-size: 20px;
      line-height: normal;
    }
    &.pageType {
      .timeContainer__time,
      .time-value {
        font-size: 45px;
        line-height: normal;

        .time-value__timezone {
          font-size: 16px;
          line-height: 36.31px;
        }
      }
    }
  }
}

@media screen and (max-width: $media-hd) {
  .timeContainer {
    .timeContainer__time,
    .time-value {
      font-size: 16px;
      line-height: normal;
      &__timezone {
        top: -2px !important;
      }
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .timeContainer {
    .timeContainer__time,
    .time-value {
      font-size: 16px;
      line-height: normal;
      &__timezone {
        top: -2px !important;
      }
    }
  }
}
