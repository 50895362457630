@import "../../index.scss";

.modal-event {
  max-width: 370px;
  width: 100%;

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  input {
    margin: 5px 0;
    text-align: left;
  }

  .edit-btn {
    bottom: 10px !important;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .label {
    display: block;
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .button:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.block-with-color {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 8px;
  background: #212745;
  border: 2px solid #181d36;
  box-shadow: -4px -4px 11px #191e35, 4px 4px 14px #293054,
    inset -4px -4px 5px rgba(5, 7, 13, 0.49),
    inset 4px 4px 29px rgba(62, 72, 118, 0.45);
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;

  &__item {
    border-radius: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    box-sizing: border-box;

    &.selected {
      border: 2px solid $blue_text;
    }
  }
}
