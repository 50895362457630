@import "../../../index.scss";
@import "../../../mixins.scss";

.add-event {
  position: relative;
  height: calc(100vh - 108px);

  .content {
    padding-top: 5px;
    display: flex;
    height: 100%;

    &__steps {
      display: flex;
      flex-wrap: nowrap;
      height: 100%;
      width: 100%;
    }

    &__tips {
      padding-top: 40px;
      padding-left: 57px;
    }

    &__pages {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      @include custom-scroll;
    }
  }

  &__img {
    img {
      right: 80px;
      position: absolute;
      bottom: 20px;
    }
  }
}

@media screen and (max-width: $media-hd) {
  .add-event {
    &__img {
      img {
        width: 300px;
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: $media-tablet) {
  .add-event {
    &__img {
      img {
        width: 250px;
        height: 250px;
      }
    }
  }
}

@media screen and (max-width: $media-tablet-small) {
  .add-event {
    &__img {
      display: none;
    }
  }
}
