@import "../../index.scss";

.modal {
  max-width: 375px;
  width: 100%;

  &__img {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    text-align: center;
    color: $white;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    display: inline-block;
    width: 100%;
    span {
      width: 100%;
      display: inline;
      color: $green_02;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin: 30px 0 20px;

    > *:not(:last-child) {
      margin-right: 50px;
    }
  }
}
