.category-name {
  width: 100%;
}

.accordion-header > button:after {
  content: none;
}

.controls {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  gap: 20px;
}

.control-button {
  cursor: pointer;
}

.nested-categories-wrapper {
  display: flex;
  width: 50%;
}

.leaf-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 20px;
}
