@import "../../index.scss";
@import "../../mixins.scss";

.statistic {
  padding: 20px 40px;

  .pageTitle {
    color: white;
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
  }

  .buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .downloadButton {
    background-color: transparent;
  }

  .availableDownloadIcon {
    svg {
      path {
        stroke: #3DDDE8;
      }
    }

    background-color: transparent;
  }

  &__date-btn {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: linear-gradient(135deg, #232948 0%, #1f2542 100%);
    box-shadow:
      3px 3px 8px 0px rgba(13, 16, 28, 0.9),
      -3px -3px 6px 0px rgba(53, 62, 110, 0.9),
      3px -3px 6px 0px rgba(13, 16, 28, 0.2),
      -3px 3px 6px 0px rgba(13, 16, 28, 0.2),
      -1px -1px 2px 0px rgba(13, 16, 28, 0.5) inset,
      1px 1px 2px 0px rgba(53, 62, 110, 0.3) inset;

    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;

    padding: 12px 24px;
    margin: 0;
    width: 245px;

    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
  }
}
