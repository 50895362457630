@import "../../index.scss";

.arrow-button {
  width: 25px;
  height: 45px;
  outline: none;
  border: none;
  background: none;

  &__icon {
    display: block;
    width: 100%;
    height: 100%;

    background: url("../../assets/controls/arrow_left.svg") no-repeat;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.9;

    .arrow-button {
      &__icon {
        background: url("../../assets/controls/arrow_left.svg") no-repeat;
      }
    }
  }
}
