.modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttons-wrapper {
  display: flex;
  gap: 5px;
}

.modal-footer {
  display: flex !important;
  justify-content: space-between !important;
}
