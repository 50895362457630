@import "../../../index.scss";

.card {
  background: #212745;
  box-shadow:
    19px 18px 11px -6px #191e35,
    -9px -7px 14px #293054,
    inset 8px 8px 10px -11px rgba(14, 21, 45, 0.49),
    inset -13px -18px 12px -7px rgba(20, 26, 54, 0.45);
  border-radius: 15px;
  max-width: 506px;
  &.modal-view {
    border-radius: 0;
    box-shadow: none;
    .card {
      &__content {
        padding: 0 15px 0;
      }
      &__info {
        overflow: hidden;
        margin-bottom: auto;
        &-row {
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  &__content {
    padding: 18px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  &__img {
    border: 2px solid #181d36;
    box-shadow:
      -4px -4px 11px #191e35,
      4px 4px 14px #293054,
      inset -4px -4px 5px rgba(5, 7, 13, 0.49),
      inset 4px 4px 29px rgba(62, 72, 118, 0.45);
    border-radius: 10px;
    overflow: hidden;
    height: 193px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    margin-bottom: 18px;
  }

  &__action {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    // padding: 0 12px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    padding: 0 12px;

    > * {
      width: 100%;
      max-width: 203px;
      min-width: unset !important;
      min-height: 64px;
    }

    > *:nth-child(1) {
      margin-right: auto;
      box-sizing: border-box;
      height: 100%;
    }

    > *:nth-child(2) {
      margin-left: auto;
    }
  }
  .text {
    width: 100% !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
  }
  &__info {
    overflow: hidden;
    margin-bottom: auto;
    &-row {
      &:not(:last-child) {
        border-bottom: 1px solid $blue_text;
      }
      padding: 20px 0;
      display: flex;
      &:last-child {
        margin-bottom: 22px;
      }
      span {
        color: $white;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
      }

      > span {
        &:nth-child(1) {
          min-width: 130px;
          max-width: 130px;
          margin-right: 15px;
        }
        &:nth-child(2) {
          width: calc(100% - 130px);
          margin-right: 15px;
        }
      }
    }
  }
  &__btns {
    padding: 10px;
    display: flex;
    justify-content: flex-end;

    > div {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
  &__btn {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: end;
    &-icon {
      margin-left: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    padding: 10px;
    background: #212745;
    border: 3px solid #1d223f;
    box-shadow:
      -4px -4px 11px #191e35,
      4px 4px 14px #293054,
      inset -4px -4px 5px #313b68,
      inset 4px 4px 8px #05070d;
    border-radius: 10px;
    margin-left: auto;
    color: #ffd01f;
    font-weight: 400;
    font-size: 23px;
    line-height: 150%;
  }
  &__vote {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 0 12px;

    span:first-child {
      text-transform: uppercase;
    }

    &-yes,
    &-no {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;
      max-width: 118px;

      border-radius: 10px;
      padding: 10px;

      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: $white;

      background: #444754;
      box-shadow:
        4px 4px 9px -1px rgba(61, 64, 77, 0.91),
        -4px -4px 7px -1px rgba(37, 39, 47, 0.9),
        inset -4px -4px 8px -1px rgba(77, 80, 90, 0.93),
        inset 3px 4px 6px -1px rgba(46, 48, 57, 0.91);
    }
    &-yes {
      margin-left: 30px;
      margin-right: 30px;

      &.selected {
        background: #4db275;
        box-shadow:
          -2px -2px 6px -1px #22603b,
          4px 4px 5px -2px #235637,
          inset 4px 4px 5px #328151,
          inset -4px -4px 29px #4bbc78;
      }
    }
    &-no {
      margin-right: 30px;
      margin-left: auto;

      &.selected {
        background: #eb4755;
        box-shadow:
          -2px -2px 9px #872a32,
          4px 4px 6px -3px #781d25,
          inset 4px 4px 5px -3px #781d25,
          inset -4px -4px 29px #cd4f59;
      }
    }
    &.active {
      cursor: pointer;

      .card__vote {
        &-no {
          background: #eb4755;
          box-shadow:
            -6px -7px 14px -2px #852830,
            4px 7px 8px 1px #3d0f13,
            inset 6px 6px 10px #c83844,
            inset -3px -3px 9px #3a1013;

          &:hover,
          &:active,
          &:focus {
            background: #eb4755;
            box-shadow:
              -2px -2px 9px #872a32,
              4px 4px 6px -3px #781d25,
              inset 4px 4px 5px -3px #781d25,
              inset -4px -4px 29px #cd4f59;
          }
        }
        &-yes {
          background: #4db275;
          box-shadow:
            -6px -7px 14px -2px #246940,
            4px 7px 8px 1px #0e2c1a,
            inset 6px 6px 10px #3c9861,
            inset -3px -3px 9px #143722;

          &:hover,
          &:active,
          &:focus {
            background: #4db275;
            box-shadow:
              -2px -2px 6px -1px #22603b,
              4px 4px 5px -2px #235637,
              inset 4px 4px 5px #328151,
              inset -4px -4px 29px #4bbc78;
          }
        }
      }
    }
    &.no-interactive {
      .card__vote {
        &-no {
          background: #eb4755;
          box-shadow:
            -2px -2px 9px #872a32,
            4px 4px 6px -3px #781d25,
            inset 4px 4px 5px -3px #781d25,
            inset -4px -4px 29px #cd4f59;
        }
        &-yes {
          background: #4db275;
          box-shadow:
            -2px -2px 6px -1px #22603b,
            4px 4px 5px -2px #235637,
            inset 4px 4px 5px #328151,
            inset -4px -4px 29px #4bbc78;
        }
      }
    }
  }
  &__button {
    margin-top: 25px;
    display: flex;
    justify-content: center;

    > *:nth-child(2) {
      margin-left: 30px;
    }

    &__pin_wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
}

.flex-direction {
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  > *:nth-child(2) {
    margin-left: 0;
  }
}

@media (min-width: 1600px) and (max-width: 1919px) {
  .card {
    &__button {
      button {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: $media-hd) {
  .card {
    &__amount {
      font-size: 16px;
    }

    &__button {
      button {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: $media-notebook) {
  .card {
    &__amount {
      font-size: 16px;
    }
  }
}
