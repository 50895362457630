@import "../../index.scss";

.validation {
  .inner {
    span {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;

      svg {
        display: block;
        margin-left: 20px;
        width: 30px;
        height: 30px;
      }
    }
  }

  .success {
    color: #16ec6b;
  }

  .error {
    color: #e63232;
  }
}
