@import "../../index.scss";
@import "../../mixins.scss";

.form-container {
  margin-top: 20px;

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__header {
      padding: 10px;
      background: #212745;
      box-shadow:
        1px 1px 2px rgba(50, 59, 104, 0.3),
        -1px -1px 2px rgba(17, 20, 35, 0.5),
        inset -1px 1px 2px rgba(17, 20, 35, 0.2),
        inset 1px -1px 2px rgba(17, 20, 35, 0.2),
        inset -1px -1px 2px rgba(50, 59, 104, 0.9),
        inset 1px 1px 3px rgba(17, 20, 35, 0.9);
      border-radius: 10px;
      margin-bottom: 150px;

      &-inner {
        padding: 20px 50px;
        background: #212745;
        box-shadow:
          -2px 2px 4px rgba(18, 21, 37, 0.2),
          2px -2px 4px rgba(18, 21, 37, 0.2),
          -2px -2px 4px rgba(48, 57, 101, 0.9),
          2px 2px 5px rgba(18, 21, 37, 0.9),
          inset 1px 1px 2px rgba(48, 57, 101, 0.3),
          inset -1px -1px 2px rgba(18, 21, 37, 0.5);
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;

        > *:nth-child(1) {
          margin-right: 40px;
        }

        > *:nth-child(2) {
          margin-right: 107px;
        }

        > *:nth-child(3) {
          margin-right: 164px;
        }
      }
    }
  }
}

@media screen and (max-width: 1920px) {
  .form-container {
    .form {
      &__header {
        &-inner {
          > *:nth-child(1) {
            margin-right: 40px;
          }

          > *:nth-child(2) {
            margin-right: auto;
          }

          > *:nth-child(3) {
            margin-right: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .form-container {
    .form {
      &__header {
        &-inner {
          flex-direction: column;
          justify-content: center;

          > * {
            margin-right: 0px !important;

            &:not(:last-child) {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }
}

.telegram-text-container {
  display: flex;
  align-items: center;
  margin-top: 60px;

  .text-container {
    width: 100%;

    &:first-child {
      margin-right: 40px;

      & > .text-container__title {
        @include gradient-text($purple_gradient);
      }
    }

    &__title {
      display: block;
      margin-bottom: 20px;
      text-align: center;

      color: #3ddde8;
      font-size: 25px;
      font-weight: 700;
      line-height: normal;
    }

    &__text-box {
      display: flex;
    }
  }

  .arrow-btn {
    background: none;
    padding: 0;
    margin: 0 10px;
  }
}
