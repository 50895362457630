@import "../../index.scss";

.modal {
  max-width: 1384px;
  width: 80%;
  max-height: 70vh;
  padding: 30px !important;
  box-sizing: border-box;

  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @include gradient-text($purple_gradient);
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
  }

  &__subtitle {
    display: block;
    color: $blue_text;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  &__close {
    button {
      cursor: pointer;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #212745;
      border: 1px solid #1e2440;
      box-shadow: -2px 2px 4px rgba(13, 16, 28, 0.2),
        2px -2px 4px rgba(13, 16, 28, 0.2), -2px -2px 4px rgba(53, 62, 110, 0.9),
        2px 2px 5px rgba(13, 16, 28, 0.9),
        inset 1px 1px 2px rgba(53, 62, 110, 0.3),
        inset -1px -1px 2px rgba(13, 16, 28, 0.5);
      border-radius: 50%;

      &:focus,
      &:active {
        box-shadow: 1px 1px 2px rgba(53, 62, 110, 0.3),
          -1px -1px 2px rgba(13, 16, 28, 0.5),
          inset -2px 2px 4px rgba(13, 16, 28, 0.2),
          inset 2px -2px 4px rgba(13, 16, 28, 0.2),
          inset -2px -2px 4px rgba(53, 62, 110, 0.9),
          inset 2px 2px 5px rgba(13, 16, 28, 0.9);
      }

      svg {
        display: block;
        width: 25px;
        height: 25px;
      }
    }
  }

  &__table {
    margin-top: 30px;
    padding: 0 18px;
    max-height: 75%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 5px;
      background: #3ddde8;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #265b62;
      border-radius: 10px;
    }
  }

  &__pagination {
    margin-top: 30px;
  }
}
