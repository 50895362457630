@import "./../../index.scss";

.box {
  width: 100%;
  height: 100%;

  &.fallback-bg {
    background: url("./../../assets/svg/img_bg.png") no-repeat center;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
